<template>
  <div>
    <VueSlickCarousel :arrows="true" v-bind="settings" class="carousel-wrapper">

      <div class="card">
        <div class="card_title">
          {{texts.main.carousel.card_1_title}}
        </div>
        <div class="card_description">
          {{texts.main.carousel.card_1_desc}}
        </div>
      </div>

      <div class="card">
        <div class="card_title">
          {{texts.main.carousel.card_2_title}}
        </div>
        <div class="card_description">
          {{texts.main.carousel.card_2_desc}}
        </div>
      </div>

      <div class="card">
        <div class="card_title">
          {{texts.main.carousel.card_3_title}}
        </div>
        <div class="card_description">
          {{texts.main.carousel.card_3_desc}}
        </div>
      </div>

      <div class="card">
        <div class="card_title">
          {{texts.main.carousel.card_4_title}}
        </div>
        <div class="card_description">
          {{texts.main.carousel.card_4_desc}}
        </div>
      </div>

      <div class="card">
        <div class="card_title">
          {{texts.main.carousel.card_5_title}}
        </div>
        <div class="card_description">
          {{texts.main.carousel.card_5_desc}}
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "MyCarousel",
  props: {},
  components: {VueSlickCarousel},
  data() {
    return {
      texts: null,
      settings: {
        "infinite": true,
        "centerMode": true,
        "centerPadding": "20px",
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "variableWidth": true
      },
    }
  },
  computed: {},
  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
}
</script>

<style lang="scss" >

.card {
  background-color: #5F5F5F;
  border-radius: 15px;
  padding: 35px;
  margin: 0 10px;
  transition: all ease .5s;
  max-width: 310px;
  height: 350px;
}

.card_title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 135%;
  color: #F6F6F6;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.card_description {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 145%;
  color: #F6F6F6;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.slick-active .card {
  max-width: 310px;
  background-color: #FFA927;
  border-radius: 15px;
  height: 400px;
  padding: 35px;
  margin: 0 10px;
}

.slick-prev, .slick-next {
  bottom: -75px;
  top: initial;
}

.slick-prev {
  left: 48%;

  @media (max-width: 768px) {
    left: 40%;
  }

  //@media only screen and (min-width: 322px) and (max-width: 375px)  {
  //  left: 42%;
  //}

}

.slick-next {
  right: 48%;

  @media (max-width: 768px) {
    left: 52%;
  }

  //@media only screen and (min-width: 322px) and (max-width: 375px)  {
  //  left: 42%;
  //}
}

.slick-prev:before, .slick-next:before {
  color: #B0B0B0;
  font-size: 25px;
}

.slick-track.slick-center {
  display: flex;
  align-items: center;
  min-height: 400px;
}

</style>

<template>
  <header id="top">
    <div class="nav">
      <div class="nav__wraper wraper">
        <nav class="nav__navigation" v-bind:class="{'scrolled': scrollPosition > 150}">
          <ul
              v-if="!isPersonalAccountPage && !isLoginPage"
              class="nav__link-block"
          >
            <li class="">
              <div class="hamburger-menu">
                <input id="menu__toggle" ref="menuToggle" type="checkbox"/>
                <label class="menu__btn" for="menu__toggle">
                  <span></span>
                </label>
                <div class="menu__box">
                  <div>
                    <ul class="mobile_ul">
                      <li style="margin-bottom: 12px">
                        <a href="/#about"  @click="close" class="menu__burger">
                          {{ texts.header.about_us }}
                        </a>
                      </li>
                      <li style="margin-bottom: 12px">
                        <div class="point"></div>
                      </li>
                      <li style="margin-bottom: 12px">
                        <a href="/#services"  @click="close" class="menu__burger">
                          {{ texts.header.services }}
                        </a>
                      </li>
                      <li style="margin-bottom: 12px">
                        <div class="point"></div>
                      </li>
                      <li style="margin-bottom: 12px">
                        <span
                            @click="gotoRoute('/Contact')"
                            class="menu__burger"
                        >{{ texts.header.contact_us }}</span
                        >
                      </li>
                      <li style="margin-bottom: 12px">
                        <div class="point"></div>
                      </li>
                      <li style="margin-bottom: 12px">
                        <span
                            @click="gotoRoute('/ApiGuide')"
                            class="menu__burger"
                        >Api Guide</span
                        >
                      </li>
                      <li style="margin-top: 81px">
                        <div class="link-block__item">
                          <el-row class="block-col-2">
                            <el-dropdown @command="selectLang">
                        <span class="el-dropdown-link-mobile" style="text-transform: uppercase">
                          {{ language }}
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="en">EN</el-dropdown-item>
                                <el-dropdown-item command="ru">RU</el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                          </el-row>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <div class="link-wrapper">
            <div class="link-block">
              <div
                  class="nav__logo"
                  @click="gotoRoute('/')"
                  v-scroll-to="'#top'"
              >
                <img src="../assets/newImg/logo.png" alt="" @click="redirect"/>
              </div>
              <div v-if="!isLoginPage" class="nav_logo_mobile">
                <div class="link-block__item mobile">
                  <a href="/#about">{{ texts.header.about_us }}</a>
                </div>

                <div class="point"></div>

                <div class="link-block__item mobile">
                  <a href="/#services">{{ texts.header.services }}</a>
                </div>

                <div class="point"></div>

                <div class="link-block__item mobile">
              <span @click="gotoRoute('/Contact')" v-scroll-to="'#top'"
              >{{ texts.header.contact_us }}</span
              >
                </div>

                <div class="point"></div>

                <div class="link-block__item mobile">
              <span @click="gotoRoute('/ApiGuide')" v-scroll-to="'#top'"
              >Api Guide</span
              >
                </div>
              </div>

            </div>

            <div v-if="!isPersonalAccountPage" class="nav__link-block">
              <div class="sbonline-button link-block__item" @click="gotoRoute('/OnlineBanking')" v-if="!isLoginPage">
                <span class="sbonline">
                  {{ texts.header.login }}
                </span>
              </div>
              <div class="sbonline-button link-block__item" @click="redirect" v-else>
                <img src="../assets/newImg/arrow.png" alt="">
                <span class="sbonline" style="margin-left: 10px">
                  {{ texts.header.back }}
                </span>
              </div>
              <div class="link-block__item">
                <el-row class="block-col-2">
                  <el-dropdown @command="selectLang">
                  <span class="el-dropdown-link" style="text-transform: uppercase">
                    {{ language }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="en">EN</el-dropdown-item>
                      <el-dropdown-item command="ru">RU</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-row>
              </div>
            </div>

            <div v-else class="nav__admin-block">
              <div class="block-logo-login">
                <a href="/" target="_blank">
                  <img src="../assets/newImg/logo.png" alt=""/>
                </a>
              </div>
              <ul class="personal-area">
                <li class="block-login">
                  <div class="block-user">
                    <span>{{ userFullName }}</span>
                  </div>
                  <div class="link-block__item__mobile">
                    <el-row class="block-col-2">
                      <el-dropdown @command="selectLang">
                      <span class="el-dropdown-link" style="text-transform: uppercase">
                        {{ language }}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="en">EN</el-dropdown-item>
                          <el-dropdown-item command="ru">RU</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-row>
                  </div>
                  <p class="block-btn-out" @click="logout()">
                    <img src="../assets/navLogin/logout.svg" alt=""/>
                    <span class="exit">{{ texts.general.exit }}</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div class="mobile_login">
            <img src="../assets/newImg/homepage/login.png" alt="" @click="gotoRoute('/OnlineBanking')">
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      language: this.$store.state.language,
      scrollPosition: 0,
      texts: null,
    };
  },

  watch: {
    language() {
      window.location.reload()
    }
  },

  mounted() {
    document.addEventListener("scroll", this.setScrollPosition);
    this.texts = this.$store.getters.getTranslate
  },

  methods: {

    close() {
      this.$refs.menuToggle.checked = false;
    },

    setScrollPosition() {
      this.scrollPosition = window.scrollY;
    },

    selectLang(lang) {
      this.language = lang;
      this.$store.commit("setLang", lang);
    },

    gotoRoute(link) {
      this.$refs.menuToggle.checked = false;
      if (link === "/Login" && this.isAuth) {
        this.$router.push("/personal-account/details");
      } else {
        this.$router.push(link);
      }
    },

    logout() {
      this.$router.push("/");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
    },

    redirect() {
      this.$router.push("/");
    }
  },

  computed: {
    ...mapGetters(["lang"]),
    userFullName() {
      return this.$store.getters.fullName;
    },
    isAuth() {
      return !!this.$store.getters.user;
    },
    isPersonalAccountPage() {
      return [
        "details",
        "transactions",
        "internationalTransfer",
        "exchangeTransfer",
        "intraTransfer",
      ].includes(this.$route.name);
    },
    isLoginPage() {
      return [
        "OnlineBanking"
      ].includes(this.$route.name)
    }
  },

  beforeDestroy() {
    document.removeEventListener("scroll", this.setScrollPosition);
  },
};
</script>

<style lang="scss" scoped>
.nav {
  width: 100%;
}

.nav__navigation {
  width: 100%;
  min-height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
  top: 0;
  height: auto;
  background: transparent;
  @media screen and (max-width: 768px) {
    justify-content: space-around;
    background: transparent;
    position: absolute;
    min-height: 60px;
  }
}

.nav__logo {
  cursor: pointer;
  margin-right: 193px;
  @media screen and (max-width: 768px) {
    top: 35px;
    left: 30px;
    margin-right: 0;
  }
}

.nav__logo img {
  @media screen and (max-width: 768px) {
    width: 131px;
    height: 22px;
  }
}

.nav__logo-block {
  width: auto;
  height: 100%;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    top: 80px;
    right: auto;
  }
}

@media screen and (max-width: 768px) {
  .mobile {
    display: none;
  }
}

.logo-img {
  width: auto;
  height: auto;
  margin-right: 13px;
}

.active-nav {
  color: #ff7f36;
}

.logo-text {
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 820px) {
    font-size: 22px;
  }

  &__hiden {
    top: -12px;
    right: -129px;
  }
}

.logo-text:after {
  content: "";
  width: 60px;
  height: 60px;
  //   background: url(../assets/header/logo.png);
  position: absolute;
  top: -73px;
  right: 93px;
  cursor: pointer;
}

.nav__link-block {
  list-style-type: none;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  height: 84px;
  display: flex;
  align-items: center;

  &:last-child {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.link-block__item {
  transition-duration: 0.1s;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  color: #5F5F5F;
  @media screen and (max-width: 520px) {
    margin-right: 5px;
  }
}

.link-block__item__mobile {
  transition-duration: 0.1s;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  color: #5F5F5F;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.nav-mobile {
  display: block;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  display: flex;
  align-items: center;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.el-dropdown-link {
  color: #000000;
}

.el-dropdown-link-mobile {
  color: #F6F6F6;
}

/* мобильное меню */
#menu__toggle {
  opacity: 0;
}

#menu__toggle:checked ~ .menu__btn {
  top: 30px;
  right: 40px;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: flex;
  width: 24px;
  height: 30px;
}

#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
  background: #2e4d4f;
  width: 20px;
  right: 7px;
}

#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
  background: #ffffff;
  width: 20px;
  display: none;
}

#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
  background: #2e4d4f;
  width: 20px;
}

#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  left: 0;
}

#menu__toggle:checked ~ label {
  height: 50px;
  padding: 0px 17px;
}

.menu__btn {
  display: flex;
  align-items: center;
  position: fixed;
  top: 9px;
  width: 29px;
  height: 26px;
  cursor: pointer;
  z-index: 20;
  @media screen and (max-width: 768px) {
    top: 27px;
  }
}

.hamburger-menu {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.nav-mobile__block {
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  margin-right: 20px;
  margin-left: 20px;
}

.nav-mobile__text {
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  color: #b1b1b1;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.menu__btn > span {
  display: block;
  position: absolute;
  width: 18px;
  height: 2px;
  background-color: #000;
  transition-duration: 0.25s;
}

.menu__btn > span::before {
  display: block;
  position: absolute;
  width: 9px;
  height: 2px;
  background-color: #000;
  transition-duration: 0.25s;
}

.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 18px;
  height: 2px;
  background-color: #000;
  transition-duration: 0.25s;
}

.menu__btn > span::before {
  content: "";
  top: -8px;
}

.menu__btn > span::after {
  content: "";
  top: 8px;
}

.menu__box {
  display: flex;
  justify-content: center;
  position: fixed;
  visibility: hidden;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: #363636;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
  transition-duration: 0.25s;
  z-index: 10;
  @media screen and (max-width: 768px) {
    background-color: #363636;
  }

  ul {
    list-style-type: none;
    text-align: start;
    @media screen and (max-width: 820px) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.menu__item {
  display: block;
  padding: 12px 24px;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #4d4d4d;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (max-width: 820px) {
    font-size: 15px;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.menu__item:hover {
  background-color: #cfd8dc;
}

.color-green {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #2e4d4f;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (max-width: 820px) {
    font-size: 15px;
    padding-right: 0;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .display-block-none {
    display: none;
  }
}

.sbonline {
  &:hover {
    transition-duration: 1s;
    color: #1d3435;
  }
}

.tel__block {
  font-family: Inter-Bold;
}

.sbonline-button {
  font-family: 'Mulish';
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #312F2D;
  background: transparent;

  border: 1px solid #312F2D;
  border-radius: 5px;

  padding: 9px 30px;
  margin-left: 31px;

  &:hover {
    border-color: #1d3435;
    transition-duration: 1s;
    color: #1d3435;
  }
}

.nav__admin-block {
  width: 96%;
  position: absolute;
  background: #fff;
  display: flex;
  left: 0;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.block-btn-out {
  display: flex;
  align-items: center;
  height: auto;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: #000000;
  cursor: pointer;
  margin-left: 31px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }

  img {
    padding-right: 15px;
    @media screen and (max-width: 600px) {
      padding-right: 10px;
    }
  }
}

.block-user {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 145%;
  color: #FFA927;
  min-width: 100px;
  text-align: right;
  margin-right: 30px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.block-login {
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding-right: 10px;
  }
}

.block-user {
  img {
    padding-right: 15px;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.block-logo-login {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 50px;
  @media screen and (max-width: 600px) {
    padding-left: 10px;
  }
}

@media screen and (max-width: 600px) {
  .block-logo-login img {
    width: 70%;
  }
}

.menu__burger {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 135%;
  text-align: center;
  color: #F6F6F6;
}

.personal-area {
  display: flex;
}

.icon-block {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    padding-left: 11px;
  }
}

.exit {
  @media screen and (max-width: 600px) {
    display: none;
  }
}


.link-block {
  display: flex;
  align-items: center;
  margin-right: 263px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.link-wrapper {
  display: flex;
  min-width: 1268px;
  justify-content: space-between;
  @media (max-width: 768px) {
    min-width: initial;
  }
}

.point {
  margin: 0 20px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #FFA927;
}

.scrolled {
  position: fixed;
  background-color: #fff;
}

.nav_logo_mobile {

  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
}

.mobile_login {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}

.mobile_ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>

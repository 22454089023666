<template>
  <div class="intra-transfer-wrapper margin-bottom-120">
    <div class="exchange-title">
      <div>{{texts.personalAccount.convert}}</div>
    </div>

    <div class="form-wrapper">
      <div class="fields">
        <div>
          <div>
            From Account
          </div>
          <div>
            <el-select
                class="fields__select"
                v-model="firstSelected"
                filterable
                placeholder="From account"
                no-data-text="List is empty"
            >
              <el-option
                  v-for="item in accountDetails"
                  :key="item.id"
                  :label="
              `${item.account_special_number} (${item.currency_abbreviation}) (${item.balance})`
            "
                  :value="item.account_special_number"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="revert" @click="revert">
          <img src="../../assets/newImg/revert.png" alt="">
        </div>

        <div>
          <div>To Account</div>
          <div>
            <el-select
                class="fields__select"
                v-model="secondSelected"
                filterable
                placeholder="To account"
                :disabled="!firstSelected"
            >
              <el-option
                  v-for="item in accountDetails"
                  :key="item.id"
                  :disabled="item.account_special_number === firstSelected"
                  :label="
              `${item.account_special_number} (${item.currency_abbreviation}) (${item.balance})`
            "
                  :value="item.account_special_number"
              >
              </el-option>
            </el-select>
          </div>
        </div>

      </div>

      <div class="fields">
        <div class="amount-block">
          <div>Amount</div>
          <div>
            <input
                class="fields__input"
                placeholder="Amount"
                v-model="amount"
                type="number"
            />
          </div>
        </div>
        <div>
          <div>Payment Reference</div>
          <div>
            <input
                class="fields__input"
                placeholder="Payment Reference"
                v-model="reference"
            />
          </div>
        </div>

      </div>
      <p v-if="showErrorMessage" class="errorMessage">
        All fields must be filled
      </p>

      <div class="btn-block">
        <div class="checkbox">
          <el-checkbox v-model="checked">
          </el-checkbox>
          <span class="agree"> {{ texts.personalAccount.agree}}</span>
          <a
              class="terms"
              type="text"
              target="_blank"
              href="/Terms_and_conditions.pdf"
          > <span class="agree" style="color: #FFA927; text-decoration-line: underline;">{{ texts.personalAccount.terms }}</span>
          </a>
        </div>
        <div>
          <button
              class="my_btn"
              @click="sendTransfer"
          >{{ texts.personalAccount.Transfer }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      firstSelected: null,
      secondSelected: null,
      amount: null,
      reference: null,
      checked: false,
      loadingSubmitButton: false,
      showErrorMessage: false,
      texts: null
    };
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },

  methods: {

    revert () {
      const first = this.firstSelected
      const second = this.secondSelected
      this.secondSelected = first
      this.firstSelected = second
    },

    async sendTransfer() {
      const { firstSelected, secondSelected, amount, reference } = this;

      if (!firstSelected || !secondSelected || !amount || !reference) {
        this.showErrorMessage = true;
        this.$message({
          message: "all fields must be filled",
          type: "warning",
        });
        return;
      }
      this.loadingSubmitButton = true;

      await this.$store
        .dispatch("sendExchangeTransfer", {
          amount,
          from_account: firstSelected,
          to_account: secondSelected,
          reference,
        })
        .then((data) => {
          if (data.code === 500 || data.message) {
            this.$message({
              message: data.message,
              type: "warning",
            });
          } else {
            this.firstSelected = null;
            this.secondSelected = null;
            this.amount = null;
            this.reference = null;
            this.checked = false;

            this.$message({
              message: "Payment order was created",
              type: "success",
            });
            this.$store.dispatch("getAccountTransaction");
          }
          this.loadingSubmitButton = false;
        });
    },
  },

  computed: {
    ...mapGetters(["lang"]),
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.intra-transfer-wrapper {
  @media screen and (max-width: 955px) {
    height: auto;
  }
}

.checkbox {
  margin-bottom: 30px;
}

.exchange-title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #312F2D;
  @media screen and (max-width: 600px) {
    width: auto;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin: 16px 16px;
  }

}

.intra-transfer-wrapper {
  padding: 60px 0 0 50px;
  min-width: 990px;
  min-height: 63vh;
  @media screen and (max-width: 1023px) {
    min-width: 100%;
    padding: 0;
    margin: 16px 0 0;
  }
  @media screen and (max-width: 600px) {
    min-width: 100%;
    padding: 0;
    margin: 16px 0 0;
  }
  h2 {
    @media screen and (max-width: 1320px) {
      text-align: center;
    }
    @media screen and (max-width: 1180px) {
      font-size: 18px;
    }
    @media screen and (max-width: 550px) {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
    @media screen and (max-width: 400px) {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
  }
}
.form-wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: 955px) {
    align-items: center;
    margin-top: 0;
  }
}

.fields {
  display: flex;
  margin-bottom: 25px;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }
  &__select {
    width: 280px;
    min-height: 45px;
    margin-right: 25px;
    margin-top: 12px;

    @media screen and (max-width: 955px) {
      width: 100%;
      margin-left: 0px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 955px) {
    flex-direction: column;
    width: 90%;
    margin-bottom: 0;
  }
}



.button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.el-button--primary {
  color: #fff;
  background: #2e4d4f;
  border: 2px solid #2e4d4f;
  width: 100%;
}

.btn-block {
  margin-top: 25px;
  margin-bottom: 124px;
  @media screen and (max-width: 600px) {
    margin-top: 24px;
    margin-bottom: 70px;
  }
}

.revert {
  display: flex;
  margin-right: 25px;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
  @media screen and (max-width: 1023px) {
    justify-content: center;
    margin-top:0;
    margin-right: 0;
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
    margin-top:0;
    margin-right: 0;
    transform: rotate(90deg);
  }
}

.amount-block {
  margin-right: 70px;
  @media screen and (max-width: 1023px) {
    margin-right: 0;
  }
}

.terms {
  margin-left: 10px;
}

.my_btn {
  margin-top: 51px;
  width: 160px;
  height: 45px;
  background: #FFA927;
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #F6F6F6;
  @media screen and (max-width: 1023px) {
    margin-top: 0;
    width: 100%;
  }
}

.fields__input {
  margin-top: 15px;
  width: 280px;
  height: 45px;
  border: none;
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.agree {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
  color: #5F5F5F;
}
</style>

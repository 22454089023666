<template>
  <div class="transaction-wrapper">
    <div class="content">
      <div class="filter-wrapper">
        <el-select
            class="filter-wrapper__select"
            v-model="selectedAccount"
            filterable
            placeholder="Filter by account"
            value-key="currency"
        >
          <!-- {{accountTransactions}} -->
          <el-option
              v-for="item in accountTransactions"
              :key="item.currency"
              :label="item.currency"
              :value="item"
          >
          </el-option>
        </el-select>
        <div @click="printTable">
          <PrintIcon />
        </div>
      </div>

      <div class="transactionsList-mobile">
        <div
            :data="currentTransactionsMobile"
        >
          <div>
            <div class="table-cell_color" v-for="t in currentTransactionsMobile" :key="t.id">
              <div class="account-value-block">
                <p class="table-cell-title">Date</p>
                <p class="table-cell">{{ t.date.slice(0,10) }}</p>
              </div>
              <div class="account-value-block">
                <p class="table-cell-title">Transfer Type</p>
                <p class="table-cell">{{ t.transfer_type_name }}</p>
              </div>
              <div class="account-value-block">
                <p class="table-cell-title">Sender</p>
                <p class="table-cell" v-if="t.transfer_type_name === 'Incoming Transfer'">{{ t.intra_to_account_number }}</p>
                <p class="table-cell" v-else>{{ t.account_special_number }}</p>
              </div>
              <div class="account-value-block">
                <p class="table-cell-title">Beneficiary</p>
                <p class="table-cell" v-if="t.transfer_type_name === 'Incoming Transfer'">{{ t.account_special_number }}</p>
                <p class="table-cell" v-else>{{ t.intra_to_account_number }}</p>
              </div>
              <div class="account-value-block">
                <p class="table-cell-title">Transaction Number</p>
                <p class="table-cell">{{ t.transfer_number }}</p>
              </div>
              <div class="account-value-block">
                <p class="table-cell-title">Debit</p>
                <p class="table-cell">{{ t.debit }}</p>
              </div>
              <div class="account-value-block">
                <p class="table-cell-title">Credit</p>
                <p class="table-cell">{{ t.credit }}</p>
              </div>
              <div class="account-value-block">
                <p class="table-cell-title">Status</p>
                <p class="table-cell">{{ t.status_name }}</p>
              </div>
              <div class="account-value-block">
                <p class="table-cell-title">Balance</p>
                <p class="table-cell">{{ t.balance }}</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="transactionsList">
        <div
            :data="currentTransactions"
            style="width: 100%; display: flex;"
        >
          <div>

            <div class="table-header ">

              <p class="table-header-title">Date</p>
              <p class="table-header-title">Transfer Type</p>
              <p class="table-header-title">Sender</p>
              <p class="table-header-title">Beneficiary</p>
              <p class="table-header-title">Payment</p>
              <p class="table-header-title">Transaction Number</p>
              <p class="table-header-title">Debit</p>
              <p class="table-header-title">Credit</p>
              <p class="table-header-title">Status</p>
              <p class="table-header-title">Balance</p>

            </div>

            <div class="table-cell_one" v-for="t in currentTransactions" :key="t.id">

              <p class="table-cell_two">{{ t.date.slice(0, 10) }}</p>
              <p class="table-cell_two">{{ t.transfer_type_name }}</p>
              <p v-if="t.transfer_type_name === 'Incoming Transfer'" class="table-cell_two">{{ t.intra_to_account_number }}</p>
              <p v-else class="table-cell_two">{{ t.account_special_number }}</p>
              <p v-if="t.transfer_type_name === 'Incoming Transfer'" class="table-cell_two">{{t.account_special_number}}</p>
              <p v-else class="table-cell_two">{{ t.intra_to_account_number }}</p>
              <p class="table-cell_two">
                <ShowMore  v-bind:item="t.reference"/>
              </p>
              <p class="table-cell_two">{{ t.transfer_number }}</p>
              <p class="table-cell_two">{{ t.debit }}</p>
              <p class="table-cell_two">{{ t.credit }}</p>
              <p class="table-cell_two">{{ t.status_name }}</p>
              <p class="table-cell_two">{{ t.balance }} {{ t.currency_abbreviation }}</p>

            </div>

          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
// import moment from 'moment'
// import PrintIcon from '../../assets/svgIcons/print'
  import ShowMore from '/src/components/UI/MyPopupStatement/MyPopupStatement'

export default {

  components: {
    ShowMore,
  },

  data() {
    return {
      selectedAccount: null,
    };
  },

  mounted() {
    this.$store.dispatch("getAccountTransaction");
  },

  methods: {
    // getDate(row, column, cellValue) {
    //   return moment(cellValue)
    //     .format('YYYY-MM-DD')
    // },
    getBalance(row) {
      return `${row.balance} (${row.currency_abbreviation})`;
    },
    printTable() {
      window.print();
    },
    showTransactions (item) {
      console.log('show transactions' + item)
    }
  },

  computed: {
    accountTransactions() {
      return this.$store.getters.accountTransactions;
    },

    currentTransactions() {
      if (this.selectedAccount) return this.selectedAccount.transaction;
      return [];
    },

    currentTransactionsMobile() {
      if (this.selectedAccount) return this.selectedAccount.transaction;
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.transaction-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  margin: 50px 0;
  width: 93%;
  min-height: 63vh;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 145%;
  display: flex;
  align-items: center;
  margin-left: 50px;
  @media screen and (max-width: 1250px) {
    width: auto;
    margin: 16px 16px;
    padding: 8px 0;
    min-height: fit-content;
  }
  @media screen and (max-width: 1000px) {
    padding: 10px 0;
  }
  @media screen and (max-width: 950px) {
    width: 90%;
  }
}

@media screen and (max-width: 1450px) {
  //.content {
  //  width: 600px;
  //}
}
@media screen and (max-width: 1070px) {
  .content {
    width: 500px;
  }
}
@media screen and (max-width: 950px) {
  .content {
    margin-left: 0px !important;
    width: 98%;
  }
}
.filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 60px;
  padding: 6px;
  margin-bottom: 40px;
  &__select {
    margin-right: 25px;
  }

  @media print {
    display: none;
  }
}
.content {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
}

.transactionsList {
  display: block;
  width: 100%;
  @media screen and (max-width: 1023px) {
    display: none;
  }
}

.table {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.transactionsList-mobile {
  display: none;
  @media screen and (max-width: 1023px) {
    display: block;
  }
}

.account-value-block {
  display: flex;
  justify-content: space-between;
  padding:0 16px;
  @media screen and (max-width: 768px) {
    padding: 0;
    margin-bottom: 8px;
  }
}

.table-cell-title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 145%;
  display: flex;
  align-items: center;
  color: #5F5F5F;
  width: 170px;
}

.table-cell {
  @media screen and (max-width: 600px) {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 145%;
    color: #5F5F5F;
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.table-cell_color {

  @media screen and (max-width: 1023px) {
    border: none;
    margin-bottom: 8px;
    padding-top: 16px;
    background-color: #fff;
    border-radius: 6px;
    padding: 8px;
  }

}

.account {
  margin-bottom: 15px;
  padding: 15px;
  background: #F8F8F8;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  cursor: pointer;
}

.table-cell_two {
  margin: 0;
  padding: 0 5px;
  min-width: 20px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-wrap: break-word ;
  -webkit-line-clamp: 3; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden;
  position: relative;

  @media print {
    border-bottom: 1px solid #edefef;
  }

}

.table-cell_one {
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
  transition: transform .2s;
}

.table-cell_one:nth-child(even) {
  background-color: #edefef;
}

.table-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
  background-color: #5F5F5F;
  color: #fff;
  border-radius: 10px 10px 0 0 ;
}

.table-header-title {
  color: #fff;
  margin: 10px 5px;
  min-height: 40px;
  display: flex;
  justify-content: flex-start;
}


</style>

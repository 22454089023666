<template>
  <div class="CryptoFriendly">
    <img class="my_img" src="../../assets/newImg/homepage/CryptoFriendly.png" alt="">
    <div class="CryptoFriendly_block">
      <div class="CryptoFriendly_title">{{ texts.services.cryptocurrency_friendly_title }}</div>
      <div class="CryptoFriendly_description">
        {{ texts.services.cryptocurrency_friendly_desc }}
      </div>
      <div class="CryptoFriendly_description">
        {{ texts.services.cryptocurrency_friendly_ul }}
      </div>
      <div class="card_line">
        <div class="point"></div>
        <div>{{ texts.services.cryptocurrency_friendly_li_1 }}</div>
      </div>
      <div class="card_line">
        <div class="point"></div>
        <div>{{ texts.services.cryptocurrency_friendly_li_2 }}</div>
      </div>
      <div class="card_line">
        <div class="point"></div>
        <div>{{ texts.services.cryptocurrency_friendly_li_3 }}</div>
      </div>
      <div class="card_line">
        <div class="point"></div>
        <div>{{ texts.services.cryptocurrency_friendly_li_4 }}</div>
      </div>
      <div class="CryptoFriendly_description">
        {{ texts.services.cryptocurrency_friendly_p }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      texts: null
    };
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
};
</script>

<style lang="scss" scoped>

.CryptoFriendly {
  margin-top: 150px;
  display: flex;
  margin-bottom: 150px;
  height: 483px;
  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.CryptoFriendly_block {
  background: #5F5F5F;
  width: 64%;
  padding: 70px 105px;
  @media (max-width: 768px) {
    padding: 40px 16px;
    width: auto;
  }
}

.CryptoFriendly_title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 135%;
  color: #F6F6F6;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.CryptoFriendly_description {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  color: #F6F6F6;
  margin-bottom: 12px;
  max-width: 610px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 145%;
  }
}

.my_img {
  width: 36%;
  @media (max-width: 768px) {
    width: auto;
  }
}

.My_btn {
  background: #FFA927;
  border-radius: 5px;
  padding: 11px 30px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #F6F6F6;
  border: none;
}

.card_line {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 145%;
  color: #F6F6F6;
}

.point {
  width: 3px;
  height: 3px;
  background: #FFA927;
  border-radius: 50%;
  margin-right: 8px;
}

</style>

import {
  Button,
  Backtop,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Input,
  Dialog,
  Select,
  Option,
  Radio,
  RadioGroup,
  Checkbox,
  Message,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Upload,
  Carousel,
  CarouselItem,
  Steps,
  Step,




} from 'element-ui'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import VueScrollTo from 'vue-scrollto'
import Vuetify from 'vuetify'
import vSelect from 'vue-select'
import 'vuetify/dist/vuetify.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';


Vue.component('v-select', vSelect)
Vue.use(Vuetify);
Vue.use(VueScrollTo)
Vue.use(Button)
Vue.use(Backtop)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Checkbox)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Upload)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Steps)
Vue.use(Step)
Vue.use(VueI18Next);


Vue.prototype.$message = Message
Vue.config.productionTip = false
const i18n = new VueI18Next(i18next);

i18next.init({
  lng: 'de',
  resources: {

  }
});

// const messages = {
//   en: {
//     name: 'Name:'
//   },
//   us: {
//     name: 'Имя'
//   }
// }


new Vue({
  router,
  store,
  Vuetify,
  vuetify : new Vuetify(),
  iconfont: 'mdi',
  render: h => h(App),
  i18n: i18n
}).$mount('#app')

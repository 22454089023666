<template>
  <div class="contact">
    <div class="contact-wrapper">
      <div class="contact-content">
        <div class="left">
          <div class="title">{{texts.contact.alexandria_bancorp_limited}}</div>
          <div class="address_block">
            <img src="../../assets/newImg/contactpage/Geo.png" alt="">
            <div class="address">5C5R+M7Q, Tifariti, SRFRB, SADR</div>
          </div>
          <div class="phone_block">
            <img src="../../assets/newImg/contactpage/Phone.png" alt="">
            <div>
              <a href="tel:+442045774328" class="phone">
                +44 2045 774 328
              </a>
            </div>
          </div>
          <div class="manager_block">
            <div class="manager">
              <img src="../../assets/newImg/contactpage/Mail.png" alt="">
              <div class="name">{{texts.contact.lina}}</div>
            </div>
            <div class="post">{{texts.contact.branch_manager}}</div>
            <div>
              <a href="mailto:lina.pyragyte@alexandriainterbu.com" class="mail">
                lina.pyragyte@alexandriainterbu.com
              </a>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">{{texts.contact.russian_representative_office}}</div>
          <div class="address_block_russian">
            <img src="../../assets/newImg/contactpage/Geo.png" alt="">
            <div class="address">{{texts.contact.address_3}}{{texts.contact.russia}}</div>
          </div>
          <div class="phone_block">
            <img src="../../assets/newImg/contactpage/Phone.png" alt="">
            <div>
              <a href="tel:+442045771040" class="phone">
                +7 499 2868838
              </a>
            </div>
          </div>
          <div class="manager_block">
            <div class="manager">
              <img src="../../assets/newImg/contactpage/Mail.png" alt="">
              <div>
                <a href="mailto:info@alexandriainterbu.com" class="phone">
                  info@alexandriainterbu.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      texts: null
    };
  },
  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
};
</script>

<style lang="scss" scoped>
.contact {
  margin-top: 443px;
  background: #E5E5E5;
  padding-bottom: 100px;
  @media (max-width: 780px) {
    margin-top: 0;
  }
}
.contact-wrapper {
  max-width: 1170px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 100px;
}

.contact-content {
  max-width: 1030px;
  display: flex;
  margin: 0 auto;
  justify-content: flex-start;
  @media (max-width: 780px) {
    flex-direction: column;
    margin-top: 255px;
  }
}

.right{
  width: 445px;
  @media (max-width: 780px) {
    width: auto;
    padding: 0 16px;
  }
}

.left {
  width: 505px;
  margin-right: 20px;
  @media (max-width: 780px) {
    width: auto;
    margin-bottom: 32px;
    margin-right: 0;
    padding: 0 16px;
  }
}

.title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #312F2D;
  margin-bottom: 40px;
  @media (max-width: 780px) {
    font-size: 20px;
  }
}

.address_block {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}

.address_block_russian {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.address {
  margin-left: 15px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  color: #5F5F5F;
}

.phone_block {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.phone {
  margin-left: 15px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  color: #5F5F5F;
}

.manager {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.name {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 145%;
  color: #5F5F5F;
  margin-left: 15px;
}

.post {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 145%;
  color: #B0B0B0;
  margin-left: 38px;
}

.mail {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  color: #5F5F5F;
  margin-left: 38px;
}
</style>

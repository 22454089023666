<template>
  <div class="my_popup" v-if="PopupVisible">
    <div class="my_popup__content">
      <div @click="hideDialog" class="close">
        <img src="../../../assets/newImg/Close.png" alt="">
      </div>
      <div class="title">
        Contact us
      </div>
      <div class="contact_form_wrapper">
        <div class="contact_form">
          <input class="my_input"
                 type="text"
                 placeholder="Name"
                 v-model="name"
          >
          <input class="my_input"
                 type="text"
                 placeholder="E-mail"
                 v-model="email"
          >
        </div>
        <div>
                <textarea class="my_textarea"
                          placeholder="Your message"
                          v-model="message"
                ></textarea>
        </div>
        <div class="my_btn_block">
          <button class="my_btn" @click="sendMessage">Send</button>
        </div>
      </div>
    </div>
    <my-popup-oops @isFailed="isFailed = false" :isFailed="isFailed" :hideParrentDialog="hideDialog"/>
    <my-popup-success @isSuccess="isSuccess = false" :isSuccess="isSuccess" :hideParrentDialog="hideDialog"/>
  </div>
</template>

<script>

import MyPopupSuccess from "../MyPopupSuccess/MyPopupSuccess";
import MyPopupOops from "../MyPopupOops/MyPopupOops";

export default {

  components: {
    MyPopupOops,
    MyPopupSuccess
  },

  props: {
    PopupVisible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      texts: null,
      name: '',
      email: '',
      message: '',
      isSuccess: false,
      isFailed: false,
    };
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },

  methods: {
    hideDialog() {
      this.$emit('PopupVisible', false)
    },

    async sendMessage() {
      await this.$store.dispatch("sendMessage", {
        "E-mail": this.email,
        name: this.name,
        message: this.message
      }).then((res) => {
        if (res.name) {
          this.isSuccess = true
        } else {
          this.isFailed = true
          console.log(res)
        }
      })
      this.email = null;
      this.name = null;
      this.message = null;
    },


  }
};
</script>

<style lang="scss" scoped>
  .my_popup {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(49,47,45, 0.55);
    position: fixed;
    display: flex;
    z-index: 1;
  }

  .my_popup__content {
    margin: auto;
    width: 700px;
    height: 602px;
    background-color: #FFFFFF;
    border-radius: 15px;
    padding: 20px;

    @media (max-width: 780px) {
      width: auto;
      height: auto;
    }
  }

  .close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .form_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .contact {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 71px;
    letter-spacing: 0.05em;
    color: #312F2D;
    margin-top: 54px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }

  .my_input {
    width: 280px;
    height: 45px;
    background: #F6F6F6;
    border-radius: 5px;
    border: none;
    padding: 13px 13px 13px 15px;
  }

  .my_input__wrapper {
    display: flex;
    justify-content: center;
  }

  .my_btn {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #FFA927;
    border: 2px solid #FFA927;
    border-radius: 5px;
    padding: 11px 30px;
    width: 160px;
  }

  .contact_form_wrapper {
    width: 670px;
    border-radius: 15px;
    padding: 35px 40px;
    bottom: -296px;
    left: 284px;
    @media (max-width: 780px) {
      width: auto;
      padding: 0;
    }
  }

  .contact_form {
    display: flex;
    justify-content: space-between;
    @media (max-width: 780px) {
      flex-direction: column;
    }
  }

  .my_input {
    padding-left: 15px;
    background: #F6F6F6;
    border-radius: 5px;
    border: none;
    width: 280px;
    height: 45px;
    margin-bottom: 16px;
  }

  .my_textarea {
    padding: 13px 16px;
    background: #F6F6F6;
    border-radius: 5px;
    border: none;
    width: 590px;
    height: 132px;
    margin-top: 30px;
    margin-bottom: 30px;
    @media (max-width: 780px) {
      width: 100%;
      margin-top: 0;
    }
  }

  .title {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 71px;
    letter-spacing: 0.05em;
    color: #312F2D;
    display: flex;
    justify-content:center;
    margin-top: 54px;
    @media (max-width: 780px) {
      font-weight: 600;
      font-size: 28px;
      line-height: 37px;
      color: #312F2D;
      margin-top: 25px;
    }
  }

  .my_btn_block {
    display: flex;
    justify-content: center;
  }
</style>

<template>
  <div class="about" id="about">
    <div class="about-wrapper">
      <h1 class="title">{{texts.main.who}}</h1>
      <div class="about-content">
        <div class="about-block ">
          <p class="text subtitle">
            {{texts.main.p1}}
          </p>
          <p class="text">
            {{texts.main.p2}}
          </p>
          <p class="text">
            {{texts.main.in}} {{texts.main.p3}}
          </p>
        </div>
      </div>
    </div>
    <div class="carousel_block">
      <MyCarousel></MyCarousel>
      <div class="line1"></div>
      <div class="line2"></div>
    </div>
  </div>
</template>

<script>

import MyCarousel from '../UI/MyCarousel/MyCarousel'

export default {
  components: {
    MyCarousel
  },
  data() {
    return {
      texts: null
    };
  },
  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
};
</script>

<style lang="scss" scoped>
.about {
  background: #F6F6F6;
  padding-top: 206px;
  color: #312F2D;
}
.about-wrapper {
  max-width: 1040px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.about-content {
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  width: 70%;
  padding-top: 25px;
  @media (max-width: 620px) {
    flex-direction: column;
    width: auto;
  }
}
.about-block {
  display: flex;
  flex-direction: column;
  @media (max-width: 620px) {
    width: 100%;
  }
}
.padding-right-100 {
  padding-right: 100px;
  @media (max-width: 620px) {
    padding-right: 0;
  }
}
.about-img-block {
  text-align: right;

  img {
    padding-bottom: 100px;
  }
  @media (max-width: 620px) {
    text-align: center;
  }
}
.title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 71px;
  letter-spacing: 0.05em;
  background: #F6F6F6;
  color: #312F2D;
  margin: 0;
  @media (max-width: 620px) {
    font-size: 28px;
    line-height: 37px;
    margin-left: 16px;
  }
}
.text {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #5F5F5F;
  padding-bottom: 24px;
  @media (max-width: 620px) {
    font-size: 14px;
    line-height: 130%;
  }
}
.btn-block {
  margin-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  @media (max-width: 620px) {
    align-self: center;
    padding-left: 0;
  }
}
.el-button {
  width: 250px;
  height: 62px;
  background: #2e4d4f;
  color: #fff;
  border: none;
  border-radius: 0;

  &:hover {
    background: #1d3435;
    transition-duration: 1s;
    color: white;
  }
}

.carousel_block {
  max-width: 1240px;
  margin: 0 auto;
  padding-top: 100px;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 32px;
  }
}

.line1 {
  border: 1px solid #FFA927;
  width: 60px;
  height: 1px;
  position: absolute;
  bottom: -57px;
  left: 521px;

  @media only screen and (min-width: 425px) and (max-width: 768px) {
    left: 264px;
  }

  @media only screen and (min-width: 375px) and (max-width: 424px) {
    left: 239px;
  }

  @media only screen and (min-width: 320px) and (max-width: 374px) {
    left: 214px;
  }
}

.line2 {
  border: 1px solid #FFA927;
  width: 60px;
  height: 1px;
  position: absolute;
  bottom: -57px;
  left: 663px;

  @media only screen and (min-width: 425px) and (max-width: 768px) {
    left: 92px;
  }

  @media only screen and (min-width: 375px) and (max-width: 424px) {
    left: 71px;
  }

  @media only screen and (min-width: 320px) and (max-width: 374px) {
    left: 49px;
  }
}

.subtitle {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 145%;
  color: #5F5F5F;
}
</style>

<template>
  <div class="main-wrapper">
    <Main />
    <About />
    <Services />
    <OnlineAccountOpening />
    <TrustAndCorporate />
    <CryptoFriendly />
    <WireTransfers />
    <PrepaidDebit />
  </div>
</template>

<script>
import Main from "./main.vue";
import About from "./aboutUs.vue";
import Services from "./Services.vue";
import OnlineAccountOpening from "./OnlineAccountOpening.vue";
import TrustAndCorporate from "./TrustAndCorporate.vue";
import CryptoFriendly from "./CryptoFriendly.vue";
import WireTransfers from "./WireTransfers.vue";
import PrepaidDebit from "./PrepaidDebit.vue";

export default {
  components: {
    Main,
    About,
    Services,
    OnlineAccountOpening,
    TrustAndCorporate,
    CryptoFriendly,
    WireTransfers,
    PrepaidDebit,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  background-color: #f6f6f6;
}

.link-style-block {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  background-color: #f8f8f8;
}

.link-style {
  text-decoration: none;
  color: #000;
  font-family: "Bodoni-Std-Roman";
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 39px;
  border-bottom: 1px solid #000;
}


</style>

<template>
  <div class="OnlineAccountOpening">
    <img class="my_img" src="../../assets/newImg/homepage/OnlineAccountOpening.png" alt="">
    <div class="OnlineAccountOpening_block">
      <div class="OnlineAccountOpening_title">{{texts.services.online_account_title}}</div>
      <div class="OnlineAccountOpening_description">
        {{texts.services.online_account_desc}}
      </div>
      <button class="My_btn" @click="PopupVisible = true">
        {{texts.general.get_started}}
      </button>
      <my-popup @PopupVisible="PopupVisible = false" :popup-visible="PopupVisible"/>
    </div>
  </div>
</template>

<script>

import MyPopup from "../UI/MyPopup/MyPopup";
export default {
  components: {
    MyPopup,
  },
  data() {
    return {
      texts: null,
      PopupVisible: false
    };
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
};
</script>

<style lang="scss" scoped>

.OnlineAccountOpening {
  margin-top: 150px;
  display: flex;
  margin-bottom: 150px;
  height: 328px;
  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
    margin-top: 48px;
    margin-bottom: 32px;
  }
}

.my_img {
  width: 36%;
  @media (max-width: 768px) {
    width: auto;
  }
}

.OnlineAccountOpening_block {
  background: #5F5F5F;
  width: 64%;
  padding: 70px 105px;
  @media (max-width: 768px) {
    width: auto;
    padding: 40px 16px;
  }
}

.OnlineAccountOpening_title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 135%;
  color: #F6F6F6;
  margin-bottom: 20px;
}

.OnlineAccountOpening_description {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  color: #F6F6F6;
  margin-bottom: 30px;
  max-width: 610px;
}

.My_btn {
  background: #FFA927;
  border-radius: 5px;
  padding: 11px 30px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #F6F6F6;
  border: none;
  width: 164px;
  height: 49px;
  box-sizing: border-box;
}

.My_btn:hover {
  background: #F6F6F6;
  border: 2px solid #FFA927;
  color: #FFA927;
  transition: all ease .3s;
}

</style>

<template>
  <div>
    <div class="fullscreen-bg">
      <div class="overlay">
        <div class="blur__block">
          <div class="blur__content">
            <div class="main_title">
              {{texts.contact.contact_us}}
            </div>

            <div class="main_subtitle_block">
              <p class="main_subtitle">
                {{texts.contact.we_offer}} {{texts.contact.we_offer_b}}{{texts.contact.we_offer_p}}
              </p>
            </div>

            <div class="scroll_down_block">
              <img src="../../assets/newImg/mini_mouse.png" alt="">
              <p class="scroll_down">Scroll down</p>
            </div>

            <div class="contact_form_wrapper">
              <div class="contact_form">
                <input class="my_input"
                       type="text"
                       placeholder="Name"
                       v-model="name"
                       @change="setIsTouch"
                >
                <input class="my_input"
                       type="text"
                       placeholder="E-mail"
                       v-model="email"
                       @change="setIsTouch"
                >
              </div>
              <div>
                <textarea class="my_textarea"
                          placeholder="Your message"
                          v-model="message"
                          @change="setIsTouch"
                ></textarea>
              </div>
              <div class="my_btn_block">
                <button class="my_btn" :class="{touch : isTouch}" @click="sendMessage">{{texts.general.send}}</button>
              </div>
            </div>


            <div class="social_links_block">
              <div class="line"></div>
              <a href="https://www.linkedin.com/company/aleksandria-bankorp/mycompany/?viewAsMember=true">
                <div class="social_link"><img src="../../assets/newImg/homepage/LinkedIN.png" alt=""></div>
              </a>
              <a href="https://t.me/AlexandriaBank">
                <div class="social_link"><img src="../../assets/newImg/homepage/Telegram.png" alt=""></div>
              </a>
              <a href="https://wa.me/+48500696001">
                <div class="social_link"><img src="../../assets/newImg/homepage/WhatsApp.png" alt=""></div>
              </a>
              <div class="line"></div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <my-popup-oops @isFailed="isFailed = false" :isFailed="isFailed"/>
    <my-popup-success @isSuccess="isSuccess = false" :isSuccess="isSuccess"/>
  </div>
</template>

<script>

import MyPopupOops from "../UI/MyPopupOops/MyPopupOops";
import MyPopupSuccess from "../UI/MyPopupSuccess/MyPopupSuccess";

export default {

  components: {
    MyPopupOops,
    MyPopupSuccess
  },

  data() {
    return {
      email: null,
      name: null,
      message: null,
      texts: null,
      isSuccess: false,
      isFailed: false,
      isTouch: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("setLoadingPage", true);
    setTimeout(() => {
      this.$store.commit("setLoadingPage", false);
    }, 1000);
    next();
  },
  methods: {

    setIsTouch () {
      this.isTouch = this.email.length > 0 && this.name.length > 0 && this.message.length > 0;
    },

    async sendMessage() {
      await this.$store.dispatch("sendMessage", {
        "E-mail": this.email,
        name: this.name,
        message: this.message
      }).then((res) => {
        if (res.name) {
          this.isSuccess = true
        } else {
          this.isFailed = true
          console.log(res)
        }
      })
      this.email = null;
      this.name = null;
      this.message = null;
    },
  },
  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  span {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 24px;
    display: flex;
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }

  h1 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #ffffff;
    margin: 0;
    padding-bottom: 30px;
    @media (max-width: 1024px) {
      font-size: 26px;
      line-height: 31px;
    }
  }
}

.blur__content {
  @media (max-width: 370px) {
    width: 80%;
    text-align: center;
    margin: 0 auto;
  }
}

.blur__img {
  width: 50%;
  height: 50%;
  text-align: center;

  img {
    @media (max-width: 1024px) {
      width: 55%;
    }
  }

  @media (max-width: 370px) {
    display: none;
  }
}

.blur__block {
  max-width: 1240px;
  margin: 0 auto;
  height: 100%;
  padding: 0 20px 0 20px;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 565px) {
    padding: 0px 20px 50px 20px;
  }
  @media (max-width: 370px) {
    padding: 0px 0px 50px 0px;
  }
}

.btn {
  text-align: left;

  .el-button {
    width: 250px;
    height: 62px;
    font-family: "Inter-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    background: #2e4d4f;
    border: none;
    border-radius: 0;

    &:hover {
      background: #1d3435;
      transition-duration: 1s;
    }
  }
}

.fullscreen-bg {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 48%;
  @media (max-width: 900px) {
    padding-top: 63%;
  }
  @media (max-width: 790px) {
    padding-top: 70%;
  }
  @media (max-width: 740px) {
    padding-top: 90%;
  }
  @media (max-width: 600px) {
    padding-top: 100%;
  }
  @media (max-width: 565px) {
    padding-top: 0;
  }
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.overlay {
  background: url("../../assets/newImg/contactpage/contactBG.png") no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media (max-width: 565px) {
    position: relative;
    height: 100vh;
  }
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.content h2 {
  text-align: center;
  font-size: 30px;
}

.content p {
  text-align: justify;
  font-size: 20px;
}

@media (max-width: 767px) {
  .fullscreen-bg__video {
    display: none;
  }
}

.arrow-down {
  position: relative;
  top: -70px;
  text-align: center;
  cursor: pointer;
}

.modal-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 780px) {
    flex-direction: column;
  }
}

.heading-text {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 25px;
  @media (max-width: 1010px) {
    font-size: 18px;
  }
}

.modal-title {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #2e4d4f;
  margin-right: 30px;
  @media (max-width: 1010px) {
    font-size: 12px;
  }
  @media (max-width: 780px) {
    margin-right: 0px;
  }
}

.modal-subtitle {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  @media (max-width: 1010px) {
    font-size: 14px;
  }
}

.modal-text-document {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-right: 5%;
}

.modal-content-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-bottom-25 {
  margin-bottom: 20px;
}

.img-document {
  padding-right: 12px;
}

.el-button--primary {
  color: #fff !important;
  background-color: #2e4d4f;
  @media (max-width: 1010px) {
    font-size: 14px !important;
  }
}

.upload-demo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputs {
  padding-bottom: 20px;
}

.el-button--primary.is-plain {
  background: #2e4d4f;
  @media (max-width: 1010px) {
    font-size: 14px !important;
  }
}

.dialog-footer {
  display: block !important;
  text-align: end !important;
}

.download-text {
  white-space: nowrap;
}

.download-block {
  justify-content: space-evenly;
}

.main_title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 71px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #F6F6F6;
  margin-top: 158px;
  margin-bottom: 25px;
  @media (max-width: 780px) {
    font-size: 28px;
    line-height: 37px;
  }
}

.main_subtitle_block {
  width: 49%;
  margin: 0 auto;
  @media (max-width: 780px) {
    width: auto;
  }
}

.main_subtitle {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  text-align: center;
  color: #F6F6F6;
  margin-bottom: 56px;
}

.scroll_down_block {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: fit-content;
}

.scroll_down {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 145%;
  text-align: center;
  color: #F6F6F6;
  margin-left: 14px;
}

.contact_form_wrapper {
  position: absolute;
  width: 670px;
  background: #FFA927;
  border-radius: 15px;
  padding: 35px 40px;
  bottom: -296px;
  left: 284px;

  @media (max-width: 780px) {
    display: flex;
    width: 288px;
    flex-direction: column;
    bottom: -315px;
    left: 12%;
    padding: 20px;
  }

  @media (max-width: 321px) {
    left: 5%;
  }

  @media only screen and (min-width: 322px) and (max-width: 375px)  {
    left: 5%;
    width: 90%;
  }

  @media only screen and (min-width: 376px) and (max-width: 425px)  {
    left: 5%;
    width: 90%;
  }

}

.contact_form {
  display: flex;
  justify-content: space-between;
  @media (max-width: 780px) {
    flex-direction: column;
  }
}

.my_input {
  padding-left: 15px;
  background: #F6F6F6;
  border-radius: 5px;
  border: none;
  width: 280px;
  height: 45px;

  @media (max-width: 780px) {
    width: auto;
    margin-bottom: 16px;
  }

}

.my_btn {
  background: #FFA927;
  border-radius: 5px;
  border: 2px solid #F6F6F6;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #F6F6F6;
  width: 160px;
  height: 45px;
}

.line {
  border: 1px solid #FFA927;
  height: 60px;
  width: 1px;
  margin-bottom: 15px;
}

.social_links_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: -13px;
  top: 31%;
  @media (min-width: 1441px) {
    top: 35%;
  }
  @media (max-width: 780px) {
    display: none;
  }
}

.social_link {
  margin-bottom: 15px;
  cursor: pointer;
}

.my_textarea {
  padding: 13px 16px;
  background: #F6F6F6;
  border-radius: 5px;
  border: none;
  width: 590px;
  height: 132px;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 780px) {
    width: 100%;
    height: 120px;
    margin-top: 0;
    box-sizing: border-box;
  }
}

.my_btn_block {
  display: flex;
  justify-content: center;
}

.touch {
  background: #F6F6F6;
  border: 2px solid #FFA927;
  color: #FFA927;
}
</style>

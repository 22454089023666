<template>
  <div>
    <div class="login">
      <div class="header-wrapper">
        <div class="header__login">
          <div class="login__content">
            <div class="login__block">
              <div v-if="!isPinMode" class="form" @keyup.enter="signIn">
                <span class="header__title">{{ texts.header.login }}</span>
                <input
                    placeholder="Login"
                    class="input"
                    type="login"
                    v-model="login"
                />
                <!-- <h1>PASSWORD</h1> -->
                <input
                    placeholder="Password"
                    class="input"
                    type="password"
                    v-model="password"
                />
                <div class="checkbox-wrapper"></div>
                <button
                    class="my_btn"
                    @click="signIn"
                    :loading="loadingSubmitButton"
                >{{texts.general.get_started}}
                </button>
                <div class="error-message">{{ this.errorMessage }}</div>
              </div>
              <div v-else class="form" @keyup.enter="confirmPin">
                <h1>PIN CODE</h1>
                <input class="input" v-model="pin"/>
                <div class="checkbox-wrapper"></div>
                <button
                    class="my_btn"
                    @click="confirmPin"
                    :loading="loadingConfirmButton"
                >
                  CONFIRM
                </button>
                <div class="error-message">{{ this.errorMessage }}</div>
              </div>
            </div>
            <div class="terms_block">
              <div>
                <a
                    class="terms"
                    type="text"
                    target="_blank"
                    href="/Terms_and_conditions.pdf"
                >
                  {{ texts.general.privacy_statement }}
                </a>
              </div>
              <div style="margin-top: 9px">© 2023 Alexandria Bancorp Limited (SADR). All rights reserved.</div>
            </div>
            <div class="links_block">
              <a href="https://www.linkedin.com/company/aleksandria-bankorp/mycompany/?viewAsMember=true">
                <div class="social_link"><img src="../../assets/newImg/homepage/LinkedIN.png" alt=""></div>
              </a>
              <a href="https://t.me/AlexandriaBank">
                <div class="social_link"><img src="../../assets/newImg/homepage/Telegram.png" alt=""></div>
              </a>
              <a href="https://wa.me/+48500696001">
                <div class="social_link"><img src="../../assets/newImg/homepage/WhatsApp.png" alt=""></div>
              </a>
            </div>

            <div class="link_block_mobile">
              <a href="https://www.linkedin.com/company/aleksandria-bankorp/mycompany/?viewAsMember=true">
                <div class="social_link"><img src="../../assets/newImg/homepage/LinkedIN.png" alt=""></div>
              </a>
              <a href="https://t.me/AlexandriaBank">
                <div class="social_link"><img src="../../assets/newImg/homepage/Telegram.png" alt=""></div>
              </a>
              <a href="https://wa.me/+48500696001">
                <div class="social_link"><img src="../../assets/newImg/homepage/WhatsApp.png" alt=""></div>
              </a>
            </div>

            <div class="terms_block_mobile">
              <div>
                <a
                    class="terms"
                    type="text"
                    target="_blank"
                    href="/Terms_and_conditions.pdf"
                >
                  {{ texts.general.privacy_statement }}
                </a>
              </div>
              <div style="margin-top: 9px; text-align: center">© 2023 Alexandria Bancorp Limited (SADR). All rights reserved.</div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      login: "",
      password: "",
      isPinMode: false,
      pin: null,
      errorMessage: null,
      loadingSubmitButton: false,
      loadingConfirmButton: false,
      texts: null,
    };
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit("setLoadingPage", true);
    setTimeout(() => {
      this.$store.commit("setLoadingPage", false);
    }, 1000);
    next();
  },

  computed: {
    ...mapGetters(["lang"]),
  },

  methods: {
    async signIn() {
      if (this.loadingSubmitButton === true) return;

      if (!this.login.trim() || !this.password.trim()) {
        this.errorMessage = "Invalid login or password";
        return;
      }

      this.loadingSubmitButton = true;
      this.$store
          .dispatch("signIn", {username: this.login, password: this.password})
          .then((response) => {
            if (response.code) throw response.message;
            this.isPinMode = true;
            sessionStorage.setItem("token", response.token);
            this.$store.commit("setUserName", this.login);
            this.$store.dispatch("requestPinCode", response.token);
            this.loadingSubmitButton = false;
            this.errorMessage = null;
          })
          .catch((error) => {
            console.log(error)
            this.errorMessage = error;
            this.loadingSubmitButton = false;
          });
    },

    confirmPin() {
      if (this.loadingConfirmButton) return;

      this.loadingConfirmButton = true;
      if (!this.pin) {
        this.errorMessage = "Invalid pin";
        this.loadingConfirmButton = false;
        return;
      }
      this.$store
          .dispatch("sendPin", this.pin)
          .then((response) => {
            if (response === "PIN IS NOT VALID") {
              this.errorMessage = "PIN IS NOT VALID";
            } else {
              this.$store.commit("setUser", response);
              this.errorMessage = false;
              this.isPinMode = false;
              this.errorMessage = null;
              this.$router.push("/personal-account/details");
            }
          })
          .catch((error) => {
            console.log(error);
            this.errorMessage = "Invalid pin";
          })
          .finally(() => {
            this.loadingConfirmButton = false;
          });
    },
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
};
</script>

<style lang="scss" scoped>
.login {
  @media screen and (max-width: 1616px) {
    padding-bottom: 60px;
  }
  @media screen and (max-width: 1200px) {
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media screen and (max-width: 750px) {
    padding-bottom: 0;
  }
}

.login__content {
  display: flex;
  justify-content: center;
  background-image: url("../../assets/newImg/login_background.png");
  background-size: cover;
  background-position: bottom;
  padding-bottom: 68px;
  height: calc(100vh - 84px);
  position: relative;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
}

.header-wrapper {
  width: 100%;
  height: auto;
  padding-top: 84px;
}

.stripes__block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 51%;
  height: 675px;
  @media (max-width: 1200px) {
    display: none;
  }

  div:first-child {
    align-self: flex-start;
    height: 50%;
    width: 50%;
  }

  div:last-child {
    height: 100%;
  }
}

.login__block {
  width: 700px;
  height: 530px;
  background: #fff;
  border-radius: 15px;
  margin-top: 186px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 170px;
    height: auto;
  }
}

.header__title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 71px;
  letter-spacing: 0.05em;
  color: #312F2D;
  margin-bottom: 50px;
  margin-top: 99px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.header__login {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  position: relative;
}

.login__icons {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  list-style-type: none;
  padding-right: 50px;
  @media screen and (max-width: 1384px) {
    font-size: 12px;
  }
  @media screen and (max-width: 550px) {
    padding-right: 10px;
    width: 300px;
  }

  img {
    @media screen and (max-width: 750px) {
      width: 20%;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    align-items: center;
    margin: 0 auto;
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    padding:20px;
    width: 100%;

  }

  h1 {
    font-family: "Inter-Regular";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #aea8a8;
    @media screen and (max-width: 600px) {
      font-size: 18px;
    }
  }

  .input {
    width: 280px;
    height: 45px;
    background: #F6F6F6;
    border-radius: 5px;
    padding: 13px 15px;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  .checkbox-wrapper {
    display: flex;
  }
}

.error-message {
  color: #cc3f55;
}

.el-button--primary {
  background: #2e4d4f;
  width: 132px;
}

.form .input {
  margin-bottom: 30px;
}

.el-button--primary {
  color: #fff;
}

.my_btn {
  border: 2px solid #FFA927;
  border-radius: 5px;
  width: 160px;
  min-height: 45px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #FFA927;
  margin-bottom: 95px;
  @media (max-width: 780px) {
    margin-bottom: 0;
  }
}

.terms_block {
  position: absolute;
  bottom: 41px;
  left: 100px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 145%;
  color: #F6F6F6;
  @media (max-width: 780px) {
    display: none;
  }
}

.terms {
  box-sizing: content-box;
  cursor: pointer;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 145%;
  color: #F6F6F6;
}

.terms:hover {
  border-bottom: 1px solid #fff;
}

.terms_block_mobile {
  display: none;
  @media (max-width: 780px) {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 145%;
    color: #F6F6F6;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
}

.links_block {
  position: absolute;
  bottom: 41px;
  right: 100px;
  display: flex;
  gap: 15px;
  @media (max-width: 780px) {
    display: none;
  }
}

.link_block_mobile {
  display: none;
  @media (max-width: 780px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    gap: 15px;
  }
}
</style>

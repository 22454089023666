<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";

export default {
  components: {
    Header,
    Footer,
  },

  created() {
    const lang = localStorage.getItem("lang");
    if (lang) {
      this.$store.commit("setLang", lang);
    }
  },

  mounted() {
    document.addEventListener("DOMContentLoaded", this.contentLoaded);
    this.$store.dispatch("getRates");
    this.$store.dispatch("getRatesCurrency");

    if (this.$route.query.newUser)
      this.$store.commit("setSpecialUserFlag", true);
  },

  computed: {
    loading: {
      set: function(value) {
        this.$store.commit("setLoadingPage", value);
      },
      get: function() {
        return this.$store.state.loadingPage;
      },
    },
    addCostyl() {
      return window.clientInformation.platform !== "Win32";
    },
  },

  methods: {
    contentLoaded() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
};
</script>
<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&family=Playfair+Display:wght@400;500;600;700;800&display=swap');

@font-face {
  font-family: "Inter-Black";
  src: url("./assets/fonts/Inter-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/fonts/Inter-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-ExtraBold";
  src: url("./assets/fonts/Inter-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-ExtraLight";
  src: url("./assets/fonts/Inter-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Light";
  src: url("./assets/fonts/Inter-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Medium";
  src: url("./assets/fonts/Inter-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter-Thin";
  src: url("./assets/fonts/Inter-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Bodoni-Std-Roman";
  src: url("./assets/fonts/Bodoni-Std-Roman.otf") format("truetype");
}
#app {
  font-family: 'Mulish','Playfair Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  // background: #fafafa;
}
ul,
li,
p,
div {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
@media screen and (max-width: 600px) {
  el-table td,
  .el-table th.is-leaf {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 500px) {
  .el-checkbox__label {
    font-size: 12px !important;
  }
}
.v-application--wrap {
  min-height: auto !important;
}
.v-modal {
  display: none;
}

.footer-bottom {
  input {
    background: #47474e;
    border-radius: 0;
    border: none;
    color: #e2e2e2;
  }
}
</style>

<template>
  <div>
    <div class="fullscreen-bg">
      <div class="overlay">
        <div class="blur__block">
          <div class="blur__content">
            <div class="main_title">
              Api guide
            </div>

            <div class="main_subtitle_block">
              <p class="main_subtitle">
                You will need your API key that you can obtain contact us.
              </p>
            </div>

            <div class="btn_block">
                <button class="my_btn" @click="PopupVisible = true">{{texts.general.get_started}}</button>
            </div>

            <div class="scroll_down_block">
              <img src="../../assets/newImg/mini_mouse.png" alt="">
              <p class="scroll_down">Scroll down</p>
            </div>


            <div class="social_links_block">
              <div class="line"></div>
              <a href="https://www.linkedin.com/company/aleksandria-bankorp/mycompany/?viewAsMember=true">
                <div class="social_link"><img src="../../assets/newImg/homepage/LinkedIN.png" alt=""></div>
              </a>
              <a href="https://t.me/AlexandriaBank">
                <div class="social_link"><img src="../../assets/newImg/homepage/Telegram.png" alt=""></div>
              </a>
              <a href="https://wa.me/+48500696001">
                <div class="social_link"><img src="../../assets/newImg/homepage/WhatsApp.png" alt=""></div>
              </a>
              <div class="line"></div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <my-popup-message @PopupVisible="PopupVisible = false" :popup-visible="PopupVisible"/>
  </div>
</template>

<script>

import MyPopupMessage from "../UI/MyPopupMessage/MyPopupMessage";

export default {

  components: {
    MyPopupMessage,
  },

  data() {
    return {
      texts: null,
      PopupVisible: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("setLoadingPage", true);
    setTimeout(() => {
      this.$store.commit("setLoadingPage", false);
    }, 1000);
    next();
  },
  methods: {
  },
  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  span {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 24px;
    display: flex;
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }

  h1 {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #ffffff;
    margin: 0;
    padding-bottom: 30px;
    @media (max-width: 1024px) {
      font-size: 26px;
      line-height: 31px;
    }
  }
}

.blur__content {
  @media (max-width: 370px) {
    text-align: center;
    margin: 0 auto;
  }
}

.blur__img {
  width: 50%;
  height: 50%;
  text-align: center;

  img {
    @media (max-width: 1024px) {
      width: 55%;
    }
  }

  @media (max-width: 370px) {
    display: none;
  }
}

.blur__block {
  max-width: 1240px;
  margin: 0 auto;
  height: 100%;
  padding: 0 20px 0 20px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  @media (max-width: 565px) {
    padding: 0px 20px 50px 20px;
  }
  @media (max-width: 370px) {
    padding: 0px 0px 50px 0px;
  }
}

.btn {
  text-align: left;

  .el-button {
    width: 250px;
    height: 62px;
    font-family: "Inter-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    background: #2e4d4f;
    border: none;
    border-radius: 0;

    &:hover {
      background: #1d3435;
      transition-duration: 1s;
    }
  }
}

.fullscreen-bg {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 48%;
  @media (max-width: 900px) {
    padding-top: 63%;
  }
  @media (max-width: 790px) {
    padding-top: 70%;
  }
  @media (max-width: 740px) {
    padding-top: 90%;
  }
  @media (max-width: 600px) {
    padding-top: 100%;
  }
  @media (max-width: 565px) {
    padding-top: 0;
  }
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.overlay {
  background: url("../../assets/newImg/contactpage/api.png") center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media (max-width: 565px) {
    position: relative;
    height: 100vh;
  }
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.content h2 {
  text-align: center;
  font-size: 30px;
}

.content p {
  text-align: justify;
  font-size: 20px;
}

@media (max-width: 767px) {
  .fullscreen-bg__video {
    display: none;
  }
}

.arrow-down {
  position: relative;
  top: -70px;
  text-align: center;
  cursor: pointer;
}
.main_title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 71px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #F6F6F6;
  margin-top: 158px;
  margin-bottom: 25px;
  @media (max-width: 780px) {
    font-size: 28px;
  }
}

.main_subtitle_block {
  margin: 0 auto;
  padding: 16px;
}

.main_subtitle {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  text-align: center;
  color: #F6F6F6;
  margin-bottom: 56px;
  @media (max-width: 780px) {
    font-size: 12px;
  }
}

.scroll_down_block {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: fit-content;
}

.scroll_down {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 145%;
  text-align: center;
  color: #F6F6F6;
  margin-left: 14px;
}

.line {
  border: 1px solid #FFA927;
  height: 60px;
  width: 1px;
  margin-bottom: 15px;
}

.social_links_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: -13px;
  top: 31%;
  @media (min-width: 1441px) {
    top: 35%;
  }
  @media (max-width: 780px) {
    display: none;
  }
}

.social_link {
  margin-bottom: 15px;
  cursor: pointer;
}

.btn_block {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

.my_btn {
  background: #FFA927;
  border-radius: 5px;
  padding: 11px 30px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #F6F6F6;
  border: none;
}

</style>

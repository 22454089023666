import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        server: 'https://api.alxbancorponline.com:8080',
        userName: null,
        pinCode: null,
        user: null,
        accountTransactions: [],
        loadingPage: true,
        rates: [],
        currencyRates: [],
        language: 'en',
        specialUserFlag: false,
        translate: {
            en: {
                general: {
                    'submit_online': 'SUBMIT ONLINE',
                    'online_account_opening_request': 'Online account opening request',
                    'download': 'Download the Account Opening Form',
                    'account_opening_form': 'Account Opening Form',
                    'account_opening_form_user': 'Account Opening Form(Person)',
                    'account_opening_form_company': 'Account Opening Form(Company)',
                    'step_1': 'Step 1',
                    'step_2': 'Step 2',
                    'upload_the_completed_and_signed_form': 'Upload the Completed and Signed Form',
                    'add': 'Add',
                    'step_3': 'Step 3',
                    'your_contact_information': 'Your Contact Information',
                    'submit': 'SUBMIT',
                    'close': 'Close',
                    'get_started': 'Get started',
                    'privacy_statement': 'Privacy Statement | Terms of Use',
                    'all_rights': 'Alexandria Bancorp Limited (SADR). All rights reserved.',
                    'exit': 'Exit',
                    'send': 'Send',

                },
                header: {
                    'services': 'Services',
                    'team': 'Team',
                    'about_us': 'About us',
                    'careers': 'Careers',
                    'contact_us': 'Contact Us',
                    'login': 'Login',
                    'back': 'Back'
                },
                main: {
                    'welcomeTo': 'Welcome to',
                    'alexandria': 'Alexandria Bancorp Limited',
                    'alexandria_bank_provides': 'Alexandria Bank (SADR) provides',
                    'non': 'Non-AEoI/CRS, Non- FATCA',
                    'other_title': 'compliant\n' +
                        '              Corporate Offshore Banking, Investment Banking, Capital Markets\n' +
                        '              and Cryptocurrency Markets.',
                    'card_title_1': 'Alexandria Bancorp Limited',
                    'card_desc_1': 'Established in 1990, Alexandria Bancorp Limited offers personalized trustee, ' +
                        'investment and private banking services to families around the globe.',
                    'card_title_2': 'Alexandria Trust Corporation',
                    'card_desc_2': 'Based in Barbados, Alexandria Trust Corporation specializes in trust and corporate ' +
                        'administration services for private clients and multi-national businesses.',
                    'card_title_3': 'Alexandria Bancorp Limited (SADR)',
                    'card_desc_3': 'Established in 2021 in SADR, a new branch that is providing Non- AEol/CRS, Non- FATCA ' +
                        ' regulated Tax-Free Accounts with the possibility to work with clients worldwide. Online Account ' +
                        'Opening has launched since the pandemic occured giving everyone a platform to develop.',
                    'who': 'About us',
                    'p1': 'Alexandria Bancorp Limited (SADR)',
                    'p2': ' Established in 2021 in SADR, a new branch that is providing Non- AEol/CRS, Non- FATCA ' +
                        'regulated Tax-Free Accounts with the possibility to work with clients worldwide. Online Account Opening ' +
                        'has launched since the pandemic occured giving everyone a platform to develop.',
                    'p3': 'In SADR, Alexandria holds a Non-AEoI/CRS, Non- FATCA compliant private Alexandria Bancorp ' +
                        'Limited (SADR) licensed by SADR and gives way to such banking advantages as: TAX-Free banking' +
                        ' solutions, Non-AEoI/CRS, Non- FATCA Offshore Corporate Banking, more comprehensible account ' +
                        'opening procedures, recognition of High-risk and other monitored jurisdictions. This jurisdiction  gave us\n' +
                        'an opportunity to reach out for a larger audience and share our profound banking experience\n' +
                        'with them. Our Tax-Free Corporate Banking is a major advantage in the modern constantly evolving world of finance.',
                    'services': 'Services',
                    'services_p1': 'At Alexandria we offer personalized services that are tailored to each of our client’s unique needs',
                    'services_p2': 'For many of our clients a diversified portfolio of the sub-funds of The Alexandria ' +
                        'Fund is appropriate. For families of substantial wealth Alexandria also offers segregated ' +
                        'investment and global custody services that are managed by dedicated professional portfolio' +
                        ' managers.',
                    'services_p3': 'Private banking',
                    'services_p4': 'At Alexandria (SADR) we maintain a number of correspondent banking relationships with ' +
                        'premier international financial institutions – both onshore and offshore. This approach provides us\n' +
                        'and our clients with access to the best cash management solutions available while ensuring flexibility at critical times.',
                    carousel: {
                        'card_1_title': 'High-Risk merchant accounts',
                        'card_1_desc': 'High-risk merchant accounts enable firms that are considered as high-risk to ' +
                            'accept online payments. Businesses searching for quick approval in the high-risk market ' +
                            'can apply to Alexandria Bancorp Limited (SADR) for merchant accounts and payment gateways ' +
                            'at competitive rates.',
                        'card_2_title': 'Free account',
                        'card_2_desc': 'We don’t charge any fixed monthly fees. The more you process, the better your fees become.',
                        'card_3_title': 'Fees',
                        'card_3_desc': '4% for a successful transaction. Final MDR will be set upon review KYC/KYB with our compliance. Payment period: T+7.',
                        'card_4_title': 'Free setup',
                        'card_4_desc': 'With Alexandria Bancorp Limited there are no setup fees. Implement our solutions and invest your money in your products.',
                        'card_5_title': 'Completely transparent',
                        'card_5_desc': 'Be sure of how much you will pay and what you are paying for from the very start. There are no hidden fees or surprises.',
                    }
                },
                services: {
                    'services': 'SERVICES',
                    'services_desc': 'At Alexandria (SADR) we offer personalized services that are tailored to each of our ' +
                        'client’s unique needs.For many of our clients a diversified portfolio of the sub-funds of The ' +
                        'Alexandria Fund is appropriate. For families of substantial wealth Alexandria also offers ' +
                        'segregated investment and global custody services that are managed by dedicated professional' +
                        ' portfolio managers.',
                    'private_banking': 'Private Banking',
                    'private_banking_desc': 'At Alexandria (SADR) we maintain a number of correspondent banking\n' +
                        '            relationships with premier international financial institutions –\n' +
                        '            both onshore and offshore. This approach provides us and our clients\n' +
                        '            with access to the best cash management solutions available while\n' +
                        '            ensuring flexibility at critical times.',
                    'private_banking_card_1': 'Personalized offshore private banking services',
                    'private_banking_card_2': 'Secured lending',
                    'private_banking_card_3': 'Foreign exchange',
                    'private_banking_card_4': 'Taking deposits in all major currencies',
                    'alexandria_fund': 'The Alexandria Fund',
                    'alexandria_fund_p_1': 'Established in the Cayman Islands in 1991, the Alexandria Fund is an\n' +
                        '            open-ended mutual fund company licensed and regulated under the\n' +
                        '            Cayman Islands Mutual Fund Law.',
                    'ul_title': 'The Alexandria Fund is an umbrella fund comprising the following six sub funds:',
                    'li_1': 'Canada Equity Fund',
                    'li_2': 'Developing Markets Fund',
                    'li_3': 'All Cap Quality Growth Fund',
                    'li_4': 'USD Money Market Fund',
                    'li_5': 'CAD Money Market Fund',
                    'li_6': 'Real Return International Bond Fund',
                    'alexandria_fund_p_2': 'Investors in The Alexandria Fund invest alongside us – and have done\n' +
                        '            so for nearly twenty years.',
                    'alexandria_fund_p_3': 'At Alexandria we do not impose entry or exit fees when our clients\n' +
                        '            invest in The Alexandria Fund and there are no conversion fees to\n' +
                        '            transfer between the funds of The Alexandria Fund. This industry\n' +
                        '            leading practice provides our clients with enhanced tactical\n' +
                        '            flexibility during times of change.',
                    'alexandria_fund_download': 'Сurrent performance of the Alexandria Fund',
                    'alexandria_fund_p_4': 'Please note that The Alexandria Fund is available only to qualified\n' +
                        '            investors and may not be available to residents of certain\n' +
                        '            jurisdictions. For more information and to learn if you qualify for\n' +
                        '            an investment in The Alexandria Fund please contact us.',
                    'online_account_title': 'Online account opening and Digital banking',
                    'online_account_desc': 'Due to the pandemic we have moved our branch to an online basis\n' +
                        '            giving an opportunity for clients that suffer from quarantine and\n' +
                        '            lockdown restrictions to manage their assets and open corporate and\n' +
                        '            personal bank accounts remotely.',
                    'cryptocurrency_friendly_title': 'Cryptocurrency-friendly',
                    'cryptocurrency_friendly_desc': 'We provide cryptocurrency accounts with a trading and exchanging\n' +
                        '            option that is managed by our private bankers in accordance with the\n' +
                        '            clients needs.',
                    'cryptocurrency_friendly_ul': 'Your Crypto Account',
                    'cryptocurrency_friendly_li_1': 'Segregated Portfolio Companies',
                    'cryptocurrency_friendly_li_2': 'Low up to 3.50% trading fee',
                    'cryptocurrency_friendly_li_3': 'Real time settlements, no transfer delays',
                    'cryptocurrency_friendly_li_4': 'A variety of coins to choose from',
                    'cryptocurrency_friendly_p': 'As with any asset, the value of digital assets can go up or down and ' +
                        'there can be substantial risk that you lose money buying, selling, holding, or investing in ' +
                        'digital assets. You should carefully consider whether trading or holding digital assets is ' +
                        'suitable for you in light of your financial condition. No Bank Guarantee and May Lose Value.',
                    'trust_and_corporate_services_title': 'Trust and Corporate Services',
                    'trust_and_corporate_services_p_1': 'Alexandria (SADR) provides its clients with comprehensive and\n' +
                        '              personalized trustee and corporate administration services,\n' +
                        '              including the provision of a company’s registered office,\n' +
                        '              corporate director and secretary, accounting services, and the\n' +
                        '              maintenance of corporate records and regulatory filings.',
                    'trust_and_corporate_services_p_2': 'For many wealthy families and individuals, trusts and corporate\n' +
                        '              entities form an essential component of their estate and wealth\n' +
                        '              management plan. Common reasons for establishing an offshore\n' +
                        '              structure include: asset protection from unforeseen potential\n' +
                        '              creditors or litigation; planning for the succession of property\n' +
                        '              and wealth; and preserving privacy, particularly when kidnapping\n' +
                        '              or violence is a concern. We would be pleased to discuss your\n' +
                        '              estate with you and your advisors.',
                    'trust_and_corporate_services_ul_1': 'Trustee Services',
                    'trust_and_corporate_services_ul_1_li_1': 'Discretionary and Non-Discretionary Trusts',
                    'trust_and_corporate_services_ul_1_li_2': 'Charitable & non-charitable purpose trusts (STAR)',
                    'trust_and_corporate_services_ul_1_li_3': 'Private Trust Companies',
                    'trust_and_corporate_services_ul_1_li_4': 'Reserved powers trusts',
                    'trust_and_corporate_services_ul_1_li_5': 'Domestic Barbados',
                    'trust_and_corporate_services_ul_1_li_6': 'Employee Benefit Trusts',
                    'trust_and_corporate_services_ul_2': 'Corporate & Other Entities',
                    'trust_and_corporate_services_ul_2_li_1': 'Exempted Companies',
                    'trust_and_corporate_services_ul_2_li_2': 'International Business Companies (IBC)',
                    'trust_and_corporate_services_ul_2_li_3': 'Resident or non-resident companies',
                    'trust_and_corporate_services_ul_2_li_4': 'Segregated Portfolio Companies',
                    'trust_and_corporate_services_ul_2_li_5': 'Companies Limited by Guarantee',
                    'trust_and_corporate_services_ul_2_li_6': 'Foundations',
                    'trust_and_corporate_services_ul_2_li_7': 'Special Purpose Vehicles',
                    'wire_transfers_title': 'Wire Transfers',
                    'wire_transfers_subtitle': 'ALEXANDRIA Bank (SADR) makes wire transfers easy and secure:',
                    'wire_transfers_card_1': 'Third-party digital payments to pre-approved payees',
                    'wire_transfers_card_2': 'Send and receive same nominee wire transfers worldwide',
                    'wire_transfers_card_3': 'Payments available in more than 20 currencies',
                    'wire_transfers_card_4': 'Tax-free transactions not subjected to currency controls',
                    'wire_transfers_card_5': 'Cash withdrawal on global ATM locations',
                    'prepaid_debit_card_title_1': 'Prepaid ',
                    'prepaid_debit_card_title_2': 'Debit Card',
                    'prepaid_debit_card_desc': 'Alexandria Bancorp Limited (SADR) Prepaid card is a reloadable Debit\n' +
                        '            card that eases your everyday expenses. It is the most efficient way\n' +
                        '            of managing your spending from your international account at\n' +
                        '            Alexandria Bancorp Limited (SADR).',
                    'prepaid_debit_card_ul': 'Key Features of the Prepaid Debit Card Include:',
                    'prepaid_debit_card_li_1': 'Available to both personal and corporate accounts holders',
                    'prepaid_debit_card_li_2': 'The card can be preloaded with up to EUR 10,000',
                    'prepaid_debit_card_li_3': 'Can be used anywhere Worldwide that displays the card logo',
                    'prepaid_debit_card_li_4': 'Cash withdrawals available from ATM machines worldwide',
                    'prepaid_debit_card_li_5': 'Enhanced 24/7 Online Services',
                    'prepaid_debit_card_li_6': 'Self Card Activation/PIN change/forgotten or lost PIN',
                    'prepaid_debit_card_li_7': 'Transfers Between Card Holders',
                    'prepaid_debit_card_li_8': '24/7 Live operator Help',
                    'prepaid_debit_card_p1': 'Alexandria Bancorp Limited Prepaid Visa card is a reloadable Debit card that eases your everyday expenses.',
                    'prepaid_debit_card_p2': 'It is the most efficient way of managing your spending from your international account at Alexandria Bancorp Limited.',
                    'num_1': 'Personalized offshore private banking services',
                    'num_2': 'Foreign exchange',
                    'num_3': 'Secured lending',
                    'num_4': 'Taking deposits in all major currencies',
                },
                about: {
                    'about_alexandria_title': 'ABOUT ALEXANDRIA',
                    'about_alexandria_subtitle': 'Alexandria Bank provides Non-AEoI/CRS, Non- FATCA compliant\n' +
                        '              Corporate Offshore Banking, Investment Banking, Capital Markets\n' +
                        '              and Cryptocurrency Markets.',
                    'who_we_are': 'Who we are?',
                    'who_we_are_desc': 'Alexandria Bancorp Limited was established in 1990 in Grand\n' +
                        '              Cayman, Cayman Islands. Alexandria Bancorp is licensed as a Class\n' +
                        '              “B” Bank and Trust Company allowing us to provide banking and\n' +
                        '              trustee services to international clients.',
                    'in_cayman': 'In Cayman',
                    'in_cayman_p': ', Alexandria holds a restricted Mutual Fund Administrator Licence\n' +
                        '              and a Securities Investment Business Licence through Alexandria\n' +
                        '              Global Investment Management Ltd. These licenses enable us to\n' +
                        '              provide investment management services as well as securities\n' +
                        '              trading and related services. Alexandria Bancorp Limited and\n' +
                        '              Alexandria Global Investment Management Ltd. are both licensed and\n' +
                        '              regulated by the Cayman Islands Monetary Authority.',
                    'in_sadr': 'In SADR',
                    'in_sadr_p1': ', Alexandria holds a Non-AEoI/CRS, Non- FATCA compliant private Trust Branch Alexandria Bancorp\n' +
                        '              Limited licensed by SADR and gives way to such banking advantages as: TAX-Free banking solutions,\n' +
                        '              Non-AEoI/CRS, Non- FATCA Offshore Corporate Banking, more comprehensible account opening procedures,\n' +
                        '              recognition of High-risk and other monitored jurisdictions. This Trust branch gave us an opportunity to\n' +
                        '              reach out for a larger audience and share our profound banking experience with them.',
                    'in_barbados': 'In Barbados',
                    'in_barbados_p_1': ', Alexandria Trust Corporation is licensed and regulated by the\n' +
                        '              Central Bank of Barbados. Alexandria Trust provides specialized\n' +
                        '              trustee and corporate administration services to private clients\n' +
                        '              and companies. Barbados, with its extensive network of tax\n' +
                        '              treaties and favourable legislation, provides an attractive\n' +
                        '              jurisdiction for international planning. Some of the more commonly\n' +
                        '              relied upon tax treaties include those with China, Latin America\n' +
                        '              or Canada.',
                },
                careers: {
                    'careers': 'CAREERS',
                    'careers_subtitle': 'Alexandria Bank provides Non-AEoI/CRS, Non- FATCA compliant\n' +
                        '              Corporate Offshore Banking, Investment Banking, Capital Markets\n' +
                        '              and Cryptocurrency Markets.',
                    'careers_desc': 'Careers',
                    'careers_desc_p_1': 'At Alexandria, we are always looking to strengthen our team by\n' +
                        '            attracting the very best individuals and providing them with the\n' +
                        '            opportunities to grow and succeed. If you are talented,\n' +
                        '            performance-driven and looking for the opportunity to show your\n' +
                        '            potential, we encourage you to explore a career with Alexandria.',
                    'careers_desc_p_2': 'Please email your resume to',
                    'careers_desc_p_2_link': 'info@alexandriabancorpkm.com.',
                    'careers_desc_p_2_after_link': 'Unfortunately, we can only respond to those applicants with whom\n' +
                        '            there may be a fit with an available position.',

                },
                contact: {
                    'contact_us': 'CONTACT US',
                    'we_offer': 'We offer',
                    'we_offer_b': 'NON - FATCA/CRS compliant',
                    'we_offer_p': 'personal and commercial banking,\n' +
                        '              wealth management and private banking, corporate and investment\n' +
                        '              banking, capital markets and Cryptocurrency markets.',
                    'sadr': 'SADR',
                    'alexandria_bancorp_limited': 'Alexandria Bancorp Limited',
                    'address': '5C5R+M7Q, Tifariti, SRFRB, SADR',
                    'lina': 'Lina Pyragyte',
                    'branch_manager': 'Branch Manager',
                    'russian_representative_office': 'Russian Representative office',
                    'address_3': 'Park "Arma" Nizhny Susalny lane, 2 5, building 19,',
                    'russia': ' Moscow, Russian Federation'
                },
                personalAccount: {
                    'client_id': 'Client ID: ',
                    'client_name': 'Client name: ',
                    'company_name': 'Company Name: ',
                    'contact_details': 'Contact details: ',
                    'client_details': 'Client Details ',
                    'account_number': 'Account Number*',
                    'beneficiary_account_number': 'Beneficiary Account Number',
                    'account_details': 'Account Details',
                    'statement': 'Statement',
                    'swift': 'SWIFT/SEPA Transfer',
                    'internal': 'Internal Transfer',
                    'exchange': 'Exchange Transfer',
                    'transaction_details': 'Transaction Details',
                    'swift_code': 'SWIFT CODE',
                    'bank_name': 'Bank Name',
                    'city': 'City ',
                    'country': 'Country ',
                    'beneficiary_address': 'Beneficiary Address ',
                    'beneficiary_name': 'Beneficiary Name ',
                    'bank_address': 'Bank Address',
                    'transfer_details': 'Transfer details',
                    'amount': 'Amount',
                    'other_details': 'Other details',
                    'purpose': 'Purpose of transfer',
                    'agree': 'I agree with Alexandria',
                    'terms': 'Terms and Conditions',
                    'Transfer': 'Transfer',
                    'all': 'All fields must be filled',
                    'convert': 'Convert Currency\n' +
                        'and/or Initiate an Exchange Transfer ',
                    'internal_transfer_desc': 'Initiate an Internal Transfer',
                }
            },
            ru: {
                general: {
                    'online_account_opening_request': 'ОНЛАЙН-ЗАПРОС НА ОТКРЫТИЕ СЧЕТА',
                    'download': 'Скачать',
                    'account_opening_form_user': 'Форма для открытия счета(Физ. лицам)',
                    'account_opening_form_company': 'Форма для открытия счета(Юр. лицам)',
                    'step_1': 'Шаг 1',
                    'step_2': 'Шаг 2',
                    'upload_the_completed_and_signed_form': 'Загрузите Заполненную и подписанную форму',
                    'add': 'добавить',
                    'step_3': 'Шаг 3',
                    'your_contact_information': 'Ваша контактная информация',
                    'submit': 'подтвердить',
                    'close': 'закрыть',
                    'get_started': 'Начать',
                    'privacy_statement': 'Политика конфиденциальности | Условия пользования',
                    'all_rights': 'Alexandria Bancorp Limited. Все права защищены.',
                    'exit': 'Выход',
                    'send': 'Отправить',
                    'pin': ''
                },
                header: {
                    'services': 'Услуги',
                    'team': 'Команда',
                    'about_us': 'О нас',
                    'careers': 'Карьера',
                    'contact_us': 'Связаться с нами',
                    'login': 'авторизироваться',
                    'back': 'назад'
                },
                main: {
                    'welcomeTo': 'Добро пожаловать',
                    'alexandria': 'ALEXANDRIA BANCORP LIMITED',
                    'alexandria_bank_provides': 'Alexandria Bancorp Limited предлагает ',
                    'non': 'корпоративные банковские услуги за рубежом вне Системы Автоматического Обмена Информацией, не подпадающие под Закон о налогообложении иностранных счетов (FATCA), ',
                    'other_title': 'инвестиционный банкинг, услуги на рынках капитала и криптовалюты.',
                    'card_title_1': 'Alexandria Bancorp Limited',
                    'card_desc_1': 'Компания Alexandria Bancorp Limited, основанная в 1990 году, предлагает персонализированные доверительные, инвестиционные и частные банковские услуги клиентам по всему миру.',
                    'card_title_2': 'Alexandria Trust Corporation',
                    'card_desc_2': 'Расположенная на Барбадос, Alexandria Trust Corporation специализируется на доверительном и корпоративном администрировании для частных клиентов и международных компаний.',
                    'card_title_3': 'Alexandria Bancorp Limited',
                    'card_desc_3': 'Основанное в 2021 в Сахарской Арабской Демократической Республике (САДР), новое отделение предлагает безналоговые банковские счета в не Системы Автоматического Обмена Информацией, не подпадающие под Закон о налогообложении иностранных счетов (FATCA), позволяющие работать с клиентами со всего мира. Открытие онлайн-счёта было запущено с началом пандемии, чтобы предоставить возможность для развития каждому',
                    'who': 'Кто мы?',
                    'p1': 'Alexandria Bancorp Limited была основана в 1990 на Гранд-Каймане, Кайманские острова.  Alexandria Bancorp Limited имеет лицензию банка класса “B” и трастовой компании, позволяющей нам предлагать банковские и трастовые услуги клиентам со всего мира.',
                    'p2': 'На Кайманах Alexandria имеет лицензию администратора ограниченного инвестиционного фонда открытого типа и лицензию на инвестиции в ценные бумаги от лица компании Alexandria Global Investment Management Ltd. Эти лицензии дают нам право на осуществление инвестиционной деятельности, также как и на операции с ценными бумагами и связанными с этим услугами. Деятельность обеих компаний – Alexandria Bancorp Limited и Alexandria Global Investment Management Ltd – осуществляется и регулируется в рамках законодательства Кайманских островов.',
                    'p3': 'В САДР Alexandria имеет частный трастовый филиал Alexandria Bancorp Limited вне Системы Автоматического Обмена Информацией, не подпадающие под Закон о налогообложении иностранных счетов (FATCA), действующий по лицензии, полученной от правительства Мвали, не подчиняющийся Центробанку САДР, что даёт такие банковские преимущества, как: безналоговые банковские услуги, вне Системы Автоматического Обмена Информацией, не подпадающие под Закон о налогообложении иностранных счетов (FATCA) банковские услуги за рубежом, более комфортные процедуры открытия банковского счёта, признание высоких рисков и юрисдикции. Этот трастовый филиал даёт нам возможность охватить большую аудиторию и поделиться с ней нашим опытом в банковской сфере.',
                    'p4': 'На Барбадосе Alexandria Trust Corporation лицензирована и регулируется Центральным банком Барбадоса. Alexandria Trust предоставляет специализированные услуги доверительного управления и корпоративного администрирования частным клиентам и компаниям. Барбадос с его обширной сетью налоговых соглашений и благоприятным законодательством представляет собой привлекательную юрисдикцию для международного планирования. Некоторые из наиболее часто используемых налоговых соглашений включают соглашения с Китаем, Латинской Америкой или Канадой.',
                    'services': 'Услуги',
                    'services_p1': 'В Alexandria мы предлагаем персонализированные услуги, адаптированные к уникальным потребностям каждого нашего клиента.',
                    'services_p2': 'Многим нашим клиентам подходит диверсифицированный портфель субфондов The Alexandria Fund. Для семей со значительным достатком Alexandria также предлагает сегрегированные инвестиции и глобальные депозитарные услуги, которыми управляют преданные своему делу профессиональные портфельные менеджеры.',
                    'services_p3': 'Личный банкинг',
                    'services_p4': 'В Alexandria (SADR) мы поддерживаем ряд корреспондентских банковских отношений с ведущими международными финансовыми учреждениями — как наземными, так и офшорными. Такой подход предоставляет нам и нашим клиентам доступ к лучшим доступным решениям по управлению денежными средствами, обеспечивая при этом гибкость в критические моменты.',
                    'team': 'Команда',
                    'about_us': 'О нас',
                    'careers': 'Карьера',
                    'contact_us': 'Связаться с нами',
                    carousel: {
                        'card_1_title': 'Мерчант-счета с высоким риском',
                        'card_1_desc': 'Мерчант-счета с высоким риском позволяют фирмам, которые считаются высокорисковыми,' +
                            ' принимать онлайн-платежи. Компании, которым требуется быстрое одобрение на рынке с высоким ' +
                            'уровнем риска, могут обратиться в Alexandria Bancorp Limited (SADR) за торговыми счетами и ' +
                            'платежными шлюзами по конкурентоспособным ценам.',
                        'card_2_title': 'Бесплатный аккаунт',
                        'card_2_desc': 'Мы не взимаем никаких фиксированных ежемесячных платежей. Чем больше вы обрабатываете, тем лучше становятся ваши сборы.',
                        'card_3_title': 'Сборы',
                        'card_3_desc': '4% за успешную транзакцию. Окончательный MDR будет установлен после проверки ' +
                            'KYC/KYB в соответствии с нашими требованиями. Срок оплаты: Т+7.',
                        'card_4_title': 'Бесплатная установка',
                        'card_4_desc': 'С Alexandria Bancorp Limited плата за установку не взимается. Внедряйте наши решения и вкладывайте деньги в свои продукты.',
                        'card_5_title': 'Полностью прозрачный',
                        'card_5_desc': 'Будьте уверены в том, сколько вы будете платить и за что вы платите с самого начала. Нет никаких скрытых комиссий или сюрпризов.',
                    }
                },
                services: {
                    'services': 'УСЛУГИ',
                    'services_desc': 'В Alexandria мы предлагаем персонализированные услуги, адаптированные к уникальным потребностям каждого нашего клиента. Многим из наших клиентов подходит диверсифицированный портфель субфондов The Alexandria Fund. Для семей со значительным достатком Alexandria также предлагает сегрегированные инвестиционные услуги и глобальные депозитарные услуги, которыми управляют преданные своему делу профессиональные портфельные менеджеры.',
                    'private_banking': 'Банкинг',
                    'private_banking_desc': 'В Alexandria мы поддерживаем корреспондентские банковские отношения с ведущими международными финансовыми учреждениями, как оншорными, так и офшорными. Подобный подход предоставляет нам и нашим клиентам лучшие пути по управлению денежными средствами и обеспечивает при этом стратегическую гибкость в критические моменты.',
                    'private_banking_card_1': 'Индивидуальные оффшорные частные банковские услуги',
                    'private_banking_card_2': 'Кредитование под обеспечение',
                    'private_banking_card_3': 'Обмен иностранной валюты',
                    'private_banking_card_4': 'Приём вкладов в основных валютах',
                    'alexandria_fund': 'The Alexandria Fund',
                    'alexandria_fund_p_1': 'Основанная на Каймановых островах в 1991 году, компания Alexandria Fund повсеместно является открытым инвестиционным фондом, лицензированным и регулируемым в соответствии с Законом о взаимных фондах Каймановых островов (ЗВФ).',
                    'ul_title': 'The Alexandria Fund ялвеятся "зонтичным" фондом, который, в свою очередь, включает в себя шесть субфондов:',
                    'li_1': 'Канадский акционерный фонд',
                    'li_2': 'Фонд рынков развития ',
                    'li_3': 'Фонд Роста Качества с Полной Капитализацией',
                    'li_4': 'Фонд валютного рынка американских долларов',
                    'li_5': 'Фонд валютного рынка канадских долларов',
                    'li_6': 'Международный фонд облигаций с реальной доходностью',
                    'alexandria_fund_p_2': 'Инвесторы The Alexandria Fund инвестируют капитал вместе с нами - и делают это уже почти двадцать лет.',
                    'alexandria_fund_p_3': 'В случае инвестирования нашими клиентами в Alexandria Fund, мы не взимаем комиссию за вход или выход, а также не взимаем комиссию за конвертацию при переводе средств между фондами The Alexandria Fund. Подобная практика, применяемая в отрасли, является ведущей и обеспечивает нашим клиентам стратегическую гибкость во времена нестабильности.',
                    'alexandria_fund_download': 'Текущие показатели the Alexandria Fund',
                    'alexandria_fund_p_4': 'Обратите внимание, что The Alexandria Fund  доступна только для квалифицированных инвесторов и может быть недоступна для резидентов некоторых стран. Для получения дополнительной информации, а также сведений об инвестировании в The Alexandria Fund, пожалуйста, свяжитесь с нами.',
                    'online_account_title': 'Открытие онлайн-счёта и Цифровой банкинг',
                    'online_account_desc': 'В связи с пандемией мы перевели наше отделение в режим «онлайн», предоставив возможность нашим клиентам, находящимся в условиях локдауна, управлять своими активами и открывать корпоративные и личные банковские счета дистанционно.',
                    'cryptocurrency_friendly_title': 'Позитивное регулирование криптовалют',
                    'cryptocurrency_friendly_desc': 'Мы предоставляем счета в криптовалюте с возможностью торговли и обмена, которые контролируются нашими частными банками в соответствии с потребностями клиентов.',
                    'cryptocurrency_friendly_ul': 'ВАШ Криптовалютный счёт',
                    'cryptocurrency_friendly_li_1': 'Покупайте, продавайте и храните цифровые активы',
                    'cryptocurrency_friendly_li_2': 'Низкие комиссии до 3.50% ',
                    'cryptocurrency_friendly_li_3': 'Расчеты в режиме реального времени, без задержек при переводе',
                    'cryptocurrency_friendly_li_4': 'Многообразие цифровых монет на выбор',
                    'cryptocurrency_friendly_p': 'Как и в случае с любыми другими активами, стоимость цифровых активов может увеличиваться или уменьшаться, существует значительный риск того, что вы можете потерять деньги, покупая, продавая, инвестируя или просто держа цифровые активы. Проанализировав свое финансовое состояние, Вы должны точно определиться, подходит ли Вам торговля и владение цифровыми активами. Не имеет банковской гарантии и может обесцениться.',
                    'trust_and_corporate_services_title': 'Трастовое и корпоративное управление',
                    'trust_and_corporate_services_p_1': 'Alexandria предоставляет своим клиентам комплексные и персонализированные услуги по трастовому и корпоративному управлению. В него входят: предоставление зарегистрированного офиса для компании, корпоративного директора и секретаря, бухгалтерских услуг, ведение корпоративной документации и подачу документов в регулирующие органы.',
                    'trust_and_corporate_services_p_2': 'Для многих обеспеченных семей / домашних хозяйств и физических лиц трасты и корпоративные структуры являются важным компонентом плана управления имуществом и состоянием. Наиболее общие причины создания офшорной структуры включают: защита активов от нежеланных потенциальных кредиторов или судебных разбирательств; планирование наследования имущества и богатства; обеспечение конфиденциальности (в случае если кто-то стал жертвой насилия или похищения). Будем рады помочь Вам и Вашим консультантам в вопросах Вашего благосостояния.',
                    'trust_and_corporate_services_ul_1': 'Трастовые услуги',
                    'trust_and_corporate_services_ul_1_li_1': 'Дискреционные и недискреционные трасты',
                    'trust_and_corporate_services_ul_1_li_2': 'Благотворительные и неблаготворительные целевые трасты',
                    'trust_and_corporate_services_ul_1_li_3': 'Частные трастовые компании ',
                    'trust_and_corporate_services_ul_1_li_4': 'Трасты с зарезервированными полномочиями',
                    'trust_and_corporate_services_ul_1_li_5': 'Отечественный Барбадос',
                    'trust_and_corporate_services_ul_1_li_6': 'Трасты стимулирования сотрудников',
                    'trust_and_corporate_services_ul_2': 'Корпоративные и Другие организации',
                    'trust_and_corporate_services_ul_2_li_1': 'Освобожденные от налогов компании',
                    'trust_and_corporate_services_ul_2_li_2': 'Международные торгово-промышленные компании',
                    'trust_and_corporate_services_ul_2_li_3': 'Компании-резиденты и -нерезиденты',
                    'trust_and_corporate_services_ul_2_li_4': 'Компании с разделенными портфелями',
                    'trust_and_corporate_services_ul_2_li_5': 'Компании с ограниченной ответственностью',
                    'trust_and_corporate_services_ul_2_li_6': 'Обоснования ',
                    'trust_and_corporate_services_ul_2_li_7': 'Транспортные средства специального назначения',
                    'wire_transfers_title': 'Банковские переводы',
                    'wire_transfers_subtitle': 'Банк ALEXANDRIA делает банковские переводы простыми и безопасными',
                    'wire_transfers_card_1': 'Сторонние цифровые платежи заранее утвержденные получателем',
                    'wire_transfers_card_2': 'Отправляйте и получайте номинальные банковские переводы по всему миру',
                    'wire_transfers_card_3': 'Осуществление платежей доступно более чем в 20 валютах ',
                    'wire_transfers_card_4': 'Операции не облагаемые налогом и не подлежащие валютному контролю',
                    'wire_transfers_card_5': 'Снятие наличных в банкоматах по всему миру',
                    'prepaid_debit_card_title_1': 'Карта ',
                    'prepaid_debit_card_title_2': 'Предоплаты',
                    'prepaid_debit_card_desc': 'Карта предоплаты Alexandria Bancorp Limited - это пополняемая дебетовая карта, которая облегчит ваши повседневные расходы. Это наиболее эффективный способ управления вашими расходами с вашего международного счета в Alexandria Bancorp Limited.',
                    'prepaid_debit_card_ul': 'Основные функции Карты предоплаты включают:',
                    'prepaid_debit_card_li_1': 'Доступна для держателей личных и корпоративных счетов.',
                    'prepaid_debit_card_li_2': 'Предварительно на карту может быть зачислено до 10 000 евро. ',
                    'prepaid_debit_card_li_3': 'Картой можно воспользоваться в любой точке мира, где есть логотип нашего банка.',
                    'prepaid_debit_card_li_4': 'Снятие наличных в банкоматах по всему миру. ',
                    'prepaid_debit_card_li_5': 'Круглосуточные онлайн-услуги',
                    'prepaid_debit_card_li_6': 'Активация карты / Изменение ПИН-кода / забытый или утерянный ПИН',
                    'prepaid_debit_card_li_7': 'Операции между держателями карт\n',
                    'prepaid_debit_card_li_8': 'Круглосуточная помощь оператора в режиме реального времени\n',
                    'num_1': 'Персонализированные оффшорные частные банковские услуги',
                    'num_2': 'Иностранная валюта',
                    'num_3': 'Обеспеченное кредитование',
                    'num_4': 'Прием депозитов во всех основных валютах',
                    'prepaid_debit_card_p1': 'Предоплаченная карта Visa Alexandria Bancorp Limited — это пополняемая дебетовая карта, которая облегчает ваши повседневные расходы.',
                    'prepaid_debit_card_p2': 'Это наиболее эффективный способ управления вашими расходами с вашего международного счета в Alexandria Bancorp Limited.',
                },
                about: {
                    'about_alexandria_title': 'О КОМПАНИИ ALEXANDRIA',
                    'about_alexandria_subtitle': 'Alexandria Bank предоставляет услуги офшорного банкинга, инвестиционного банкинга, рынков капитала и рынков криптовалют, вне Системы Автоматического Обмена Информацией и не подпадающие под Закон о налогообложении иностранных счетов (FATCA)',
                    'who_we_are': 'Кто мы такие?',
                    'who_we_are_desc': 'Компания Alexandria Bancorp Limited была основана в 1990 в Большой Каймани, Каймановы острова. Alexandria Bancorp имеет лицензию банка и трастовой компании типа В, что позволяет ей предоставлять банковские и трастовые услуги международным клиентам.',
                    'in_cayman': 'На Каймановых островах ',
                    'in_cayman_p': 'Alexandria владеет ограниченной лицензией Управляющего паевых фондов, а также лицензию на ведение бизнеса по инвестированию в ценные бумаги через Alexandria Global Investment Management Ltd. Данные лицензии позволяют нам предоставлять услуги по управлению инвестициями, а также осуществлять торговлю ценными бумагами. Alexandria Bancorp Limited и Alexandria Global Investment Management Ltd. лицензированы и регулируются Валютным управлением Каймановых островов.',
                    'in_sadr': 'В САДР',
                    'in_sadr_p1': ' у Alexandria существует лицензированный частный трастовый филиал Alexandria Bancorp Limited, действующий вне Системы Автоматического Обмена Информацией и не подпадающий под Закон о налогообложении иностранных счетов (FATCA). Это дарит такие преимущества как необлагаемые налогом банковские решения, офшорные корпоративные банки действующий вне Системы Автоматического Обмена Информацией и не подпадающий под Закон о налогообложении иностранных счетов (FATCA), более понятные процедуры открытия счетов, признание юрисдикций высокого риска и других контролируемых юрисдикций. Такой трастовый филиал дарит нам возможность охватить более широкую аудиторию и поделиться нашим глубоким опытом в сфере банковского дела.',
                    'in_barbados': 'На Барбадосе',
                    'in_barbados_p_1': 'деятельность Alexandria Trust Corporation лицензирована и регулируется Центральным банком Барбадоса. Alexandria Trust специализируется на услугах по трастовому и к корпоративному администрированию для частных клиентов и компаний. Барбадос с его обширной сетью налоговых соглашений и благоприятным законодательством является привлекательной юрисдикцией для международного планирования. Некоторые из наиболее часто используемых налоговых соглашений включают соглашения с Китаем, Латинской Америкой и Канадой.',
                },
                careers: {
                    'careers': 'ПРОФЕССИОНАЛЬНАЯ ДЕЯТЕЛЬНОСТЬ',
                    'careers_subtitle': 'Alexandria Bancorp Limited предлагает корпоративные банковские услуги за рубежом вне Системы Автоматического Обмена Информацией, не подпадающие под Закон о налогообложении иностранных счетов (FATCA), инвестиционный банкинг, услуги на рынках капитала и криптовалюты.',
                    'careers_desc': 'Карьера ',
                    'careers_desc_p_1': 'В Alexandria мы всегда стремимся к созданию эффективной команды, привлекая лучших сотрудников и обеспечивая их возможностями для карьерного роста и успеха. Если вы креативны, ориентированы на результат и ищете возможность раскрыть свой потенциал, рекомендуем Вам рассмотреть возможности карьерного роста в Alexandria.',
                    'careers_desc_p_2': 'Пожалуйста, пришлите свое резюме в',
                    'careers_desc_p_2_link': 'info@alexandriabancorpkm.com.',
                    'careers_desc_p_2_after_link': 'К сожалению, мы можем дать обратную связь претендентам только при наличии свободных вакансий.',
                },
                contact: {
                    'contact_us': 'СВЯЖИТЕСЬ С НАМИ',
                    'we_offer': 'Мы предлагаем',
                    'we_offer_b': 'совместимые персональные и коммерческие банковские услуги, не попадающие под закон «О налогообложении иностранных счетов», управление состоянием и частные банковские услуги,',
                    'we_offer_p': ' корпоративные и инвестиционные банковские услуги, рынки капитала и рынки криптовалют.',
                    'sadr': 'САДР',
                    'alexandria_bancorp_limited': 'Alexandria Bancorp Limited',
                    'address': '5C5R+M7Q, Tifariti, SRFRB, SADR',
                    'lina': 'Лина Пирагайт',
                    'branch_manager': 'Менеджер филиала',
                    'russian_representative_office': 'Представительство в россии',
                    'address_3': 'Бизнес-парк «Арма» Нижний Сусальный переулок, д. 5, стр.19, Москва, ',
                    'russia': 'РФ'
                },
                personalAccount: {
                    'client_id': 'ID клиента ',
                    'client_name': 'Имя клиента: ',
                    'company_name': 'Название компании: ',
                    'contact_details': 'Контактная информация: ',
                    'client_details': 'Информация о клиенте ',
                    'account_number': 'номер счёта',
                    'account_details': 'Реквизиты счёта',
                    'statement': 'Заявление',
                    'swift': 'Перевод SWIFT/SEPA',
                    'internal': 'Внутренний перевод',
                    'exchange': 'Перевод между счетами',
                    'transaction_details': 'Сведения о транзакции',
                    'swift_code': 'SWIFT-КОД',
                    'bank_name': 'Название банка',
                    'country': 'Страна ',
                    'city': 'Город  ',
                    'beneficiary_address': 'Адрес получателя ',
                    'beneficiary_name': 'Имя получателя ',
                    'bank_address': 'Адрес банка',
                    'transfer_details': 'Детали перевода: ',
                    'amount': 'Сумма',
                    'other_details': 'Прочие сведения',
                    'purpose': 'Цель перевода',
                    'agree': 'Я согласен(на) с',
                    'terms' : ' Положениями и Условиями Alexandria',
                    'Transfer': 'Оплатить',
                    'all': 'все поля должны быть заполнены',
                    'convert': 'Конвертировать валюту\n' +
                        'и/или инициировать обменный перевод',
                    'internal_transfer_desc': 'Инициировать внутренний перевод',
                }
            }
        }
    },
    getters: {
        getTranslate(state) {
            return state.translate[state.language]
        },
        fullName(state) {
            const {user} = state

            if (!user) return ''

            return `${user.client_last_name} ${user.client_first_name}`
        },
        user(state) {
            return state.user
        },
        accountDetails(state) {
            if (!state.user) return []

            return state.user.accounts
        },
        accountTransactions(state) {
            return state.accountTransactions
        },
        specialUserFlag(state) {
            return state.specialUserFlag
        },
    },

    mutations: {

        setUserName(state, username) {
            state.userName = username
        },

        setUser(state, user) {
            sessionStorage.setItem("user", JSON.stringify(user));
            state.user = user
        },

        setAccountTransactions(state, accountTransactions) {
            state.accountTransactions = accountTransactions

        },
        setLoadingPage(state, value) {
            state.loadingPage = value
        },
        setRates(state, rates) {
            state.rates = rates
        },
        setCurrencyRates(state, rates) {
            state.currencyRates = rates;
        },
        setLang(state, lang) {
            state.language = lang
            localStorage.setItem('lang', lang)
        },
        setSpecialUserFlag(state, param) {
            console.log('specialUserFlag', param)
            state.specialUserFlag = param
        },
    },
    actions: {
        signIn({state}, params) {
            return fetch(`${state.server}/api/login_check`, {
                method: 'POST',
                body: JSON.stringify(params),
            }).then(response => response.json())
        },

        changePassword({state}, params) {
            const token = sessionStorage.getItem('token')
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
            return fetch(`${state.server}/user/changes/passwords`, {
                method: 'POST',
                headers,
                body: JSON.stringify(params),
            }).then(response => response.json())
        },

        requestPinCode({state}, token) {
            const headers = {
                Authorization: `Bearer ${token}`,
            }
            return fetch(`${state.server}/user/sends/users/pins`, {
                method: 'POST',
                headers,
            }).then(response => response.json())
                .then((response) => {
                    state.pinCode = response[1]
                    return response[1]
                })
        },

        sendPin({state}, pin) {
            const token = sessionStorage.getItem('token')
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
            return fetch(`${state.server}/user/verifications`, {
                method: 'POST',
                body: JSON.stringify({pin}),
                headers,
            }).then(response => response.json())
        },

        userDetails({state}) {
            const token = sessionStorage.getItem('token')
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
            return fetch(`${state.server}/user/data`, {
                method: 'GET',
                headers,
            }).then(response => response.json())
        },

        getAccountTransaction({state, commit}) {
            const token = sessionStorage.getItem('token')
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
            return fetch(`${state.server}/api/transfeers/gets/users/accounts/details`, {
                method: 'POST',
                headers,
            }).then(response => response.json())
                .then((data) => {
                    commit('setAccountTransactions', data)
                    return data
                })
        },

        sendInterTransfer({state}, params) {
            const token = sessionStorage.getItem('token')
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
            return fetch(`${state.server}/api/transfeers/payments/requests/applications`, {
                method: 'POST',
                headers,
                body: JSON.stringify(params),
            }).then(response => response.json())
        },

        sendExchangeTransfer({state}, params) {
            const token = sessionStorage.getItem('token')
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
            return fetch(`${state.server}/api/transfeers/internals/a2as/applications `, {
                method: 'POST',
                headers,
                body: JSON.stringify(params),
            }).then(response => response.json())
        },

        sendIntraTransferA2a({state}, params) {
            const token = sessionStorage.getItem('token')
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
            return fetch(`${state.server}/api/transfeers/internals/applications`, {
                method: 'POST',
                headers,
                body: JSON.stringify(params),
            }).then(response => response.json())
        },

        sendMessage({state}, params) {
            const token = sessionStorage.getItem('token')
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
            return fetch(`${state.server}/mailer/contacts/forms`, {
                method: 'POST',
                headers,
                body: JSON.stringify(params),
            }).then(response => response.json())
        },

        signUp({state}, params) {
            const token = sessionStorage.getItem('token')
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
            return fetch(`${state.server}/mailer/onlines/registrations`, {
                method: 'POST',
                headers,
                body: JSON.stringify(params),
            }).then(response => response.json())
        },

        getRates({commit}) {
            const currenciesName = ['bitcoin', 'ethereum', 'tether', 'ripple', 'bitcoin-cash', 'cardano', 'bitcoin-sv', 'chainlink', 'litecoin', 'binance-coin']
            const url = 'https://api.coincap.io/v2/assets?ids=' + currenciesName.join()

            fetch(url, {
                method: 'GET',
            }).then(response => response.json())
                .then(({data}) => {
                    commit('setRates', data)
                })
        },
        getRatesCurrency({commit}) {
            const currenciesName = ['USD', 'EUR', 'GBP', 'RUB', 'AED', 'CHF', 'JPY', 'TRY']
            const url = 'https://api.currencyfreaks.com/latest?apikey=e6f8b1008313432c8d842de6cad73743&symbols=' + currenciesName.join()

            fetch(url, {
                method: 'GET',
            }).then(response => {

                return response.json();

            })
                .then((data) => {
                    commit('setCurrencyRates', data.rates)
                })
        },
    },
})


export default store


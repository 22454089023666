<template>
  <div class="main-wrapper">
    <Main/>
    <ApiGuides/>
  </div>
</template>

<script>
import Main from "./main.vue";
import ApiGuides from "./apiGuides.vue";

export default {
  components: {
    Main,
    ApiGuides
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  background-color: #f6f6f6;
}
</style>

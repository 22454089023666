<template>
  <div class="services" id="services" >
    <div class="services-wrapper" >
      <h1 class="title">{{texts.main.services}}</h1>
      <div class="services-content">
        <div class="services-block ">
          <p class="text subtitle">
            {{texts.main.services_p1}}
          </p>
          <p class="text">
            {{texts.main.services_p2}}
          </p>
          <p class="text subtitle">
            {{texts.main.services_p3}}
          </p>
          <p class="text">
            {{texts.main.services_p4}}
          </p>
        </div>
      </div>
    </div>
    <div class="num_block">
      <div class="num_block-card">
        <div class="card_number_block">
            <div class="card_number">Num. 1</div>
            <div class="services-line"></div>
        </div>
        <div class="card_description">
          {{texts.services.num_1}}
        </div>
      </div>
      <div class="num_block-card">
        <div class="card_number_block">
          <div class="card_number">Num. 2</div>
          <div class="services-line"></div>
        </div>
        <div class="card_description">
          {{texts.services.num_2}}
        </div>
      </div>
      <div class="num_block-card">
        <div class="card_number_block">
          <div class="card_number">Num. 3</div>
          <div class="services-line"></div>
        </div>
        <div class="card_description">
          {{texts.services.num_3}}
        </div>
      </div>
      <div class="num_block-card">
        <div class="card_number_block">
          <div class="card_number">Num. 4</div>
          <div class="services-line"></div>
        </div>
        <div class="card_description">
          {{texts.services.num_4}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      texts: null
    };
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
};
</script>

<style lang="scss" scoped>
.services {
  max-width: 1240px;
  margin: 0 auto;
  background: #F6F6F6;
  color: #312F2D;
  padding-top: 170px;
  @media (max-width: 768px) {
    padding-top: 120px;
  }
}
.services-wrapper {
  max-width: 1040px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.services-content {
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  width: 70%;
  padding-top: 25px;
  @media (max-width: 620px) {
    flex-direction: column;
    width: auto;
  }
}
.services-block {
  display: flex;
  flex-direction: column;
  @media (max-width: 620px) {
    width: 100%;
  }
}
.padding-right-100 {
  padding-right: 100px;
  @media (max-width: 620px) {
    padding-right: 0;
  }
}
.services-img-block {
  text-align: right;

  img {
    padding-bottom: 100px;
  }
  @media (max-width: 620px) {
    text-align: center;
  }
}
.title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 71px;
  letter-spacing: 0.05em;
  background: #F6F6F6;
  color: #312F2D;
  margin: 0;
  @media (max-width: 620px) {
    font-size: 24px;
    line-height: 29px;
    margin-left: 16px;
  }
}
.text {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #5F5F5F;
  padding-bottom: 24px;
  @media (max-width: 620px) {
    font-size: 14px;
    line-height: 130%;
  }
}
.subtitle {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 145%;
  color: #5F5F5F;
}

.num_block {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 32px;
    margin-left: 16px;
  }
}

.num_block-card {
  width: 290px;
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
}

.card_number_block {
  display: flex;
  align-items: center;
}

.card_number {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 135%;
  color: #FFA927;
}

.card_description {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 135%;
  color: #5F5F5F;
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-top: 8px;
  }
}

.services-line {
  border: 1px solid #FFA927;
  width: 211px;
  height: 1px;
  margin-left: 20px;
}
</style>

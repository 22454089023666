<template>
  <div class="details-wrapper">
    <div class="user-wrapper">
      <div class="content">
        <div class="title">{{ texts.personalAccount.client_details }}</div>
        <div class="user-data-block">
          <div>
            <div class="user">
              <span class="user__key">{{ texts.personalAccount.client_id }}</span>
              <span class="user__value">{{ user.client_id }}</span>
            </div>
            <div class="user">
              <span class="user__key">{{ texts.personalAccount.client_name }}</span>
              <span class="user__value">{{
                  `${user.client_last_name} ${user.client_first_name}`
                }}</span>
            </div>
          </div>
          <div>
            <div class="user">
              <span class="user__key">{{ texts.personalAccount.company_name }}</span>
              <span class="user__value">{{ user.company_name }}</span>
            </div>
            <div class="user">
              <span class="user__key">{{ texts.personalAccount.contact_details }}</span>
              <span class="user__value">{{ user.email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DetailsTable />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DetailsTable from "./DetailsTable.vue";

export default {
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      loadingChangeBtn: true,
      errorMessage: "The password contains less than 8 characters",
      firstInpError: false,
      secondInpError: false,
      thirdInpError: false,
      notMatch: false,
      texts: null
    };
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },

  created() {
    if (!this.$store.getters.user) {
      this.$store.commit("setUser", JSON.parse(sessionStorage.getItem("user")));
    }
  },

  methods: {
    async changePassword() {
      const {
        firstInpError,
        secondInpError,
        thirdInpError,
        currentPassword,
        newPassword,
        repeatNewPassword,
      } = this;

      if (currentPassword.length < 8) this.firstInpError = true;
      if (newPassword.length < 8) this.firstInpError = true;
      if (repeatNewPassword.length < 8) this.firstInpError = true;
      if (firstInpError || secondInpError || thirdInpError) return;
      if (newPassword !== repeatNewPassword) {
        this.notMatch = true;
        return;
      }

      await this.$store.dispatch("changePassword", {
        oldPassword: currentPassword,
        newPassword,
      });
    },
  },

  computed: {
    ...mapGetters(["lang"]),
    user() {
      return this.$store.getters.user;
    },
  },

  components: {
    DetailsTable,
  },
};
</script>

<style lang="scss" scoped>

.content {
  font-family: 'Playfair Display';
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.details-wrapper {
  margin: 50px 50px;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  min-height: 63vh;
  border-radius: 6px;
  @media screen and (max-width: 1250px) {
    min-width: 92%;
    margin: 16px 16px;
    padding: 8px 0;
    min-height: fit-content;
  }
  @media screen and (max-width: 600px) {
    min-width: 92%;
    margin: 16px 16px;
    padding: 8px 0;
    min-height: fit-content;
  }
}

.user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 874px;
  @media screen and (max-width: 1390px) {
    width: auto;
  }
  @media screen and (max-width: 1000px) {
    width: 90%;
    margin-bottom: 16px;
  }
}

.user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;

  &__key {
    width: 115px;
    display: block;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: #363636;
    text-align: left;
    margin-bottom: 12px;
    margin-right: 50px;
    @media screen and (max-width: 600px) {
      width: 100px;
      font-weight: 400;
      font-size: 12px;
      line-height: 145%;
      color: #312F2D;
      margin-right: 0;
    }
  }

  &__value {
    text-align: left;
    margin-bottom: 12px;
    font-family: "Inter-Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #363636;

    @media screen and (max-width: 955px) {
      width: 50%;
    }
    @media screen and (max-width: 600px) {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      line-height: 145%;
      color: #5F5F5F;
    }
  }
}
.change-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 555px;
  background: rgba(228, 229, 215, 0.85);
  padding: 25px 25px;

  &__input {
    margin-bottom: 25px;
    width: 165px;
  }

  &__label {
    display: flex;
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #000;
  }

  @media screen and (max-width: 955px) {
    width: 96%;
  }
}
.change-password__input {
  width: 395px;
}
.error-message {
  color: #cc3f55;
  font-size: 12px;
}

.change-button {
  margin-top: 25px;
}
.el-button {
  width: 150px;
  background: #385a41;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.el-table .cell {
  word-break: break-word !important;
}
.title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #312F2D;
  margin-bottom: 40px;
    @media screen and (max-width: 600px) {
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 18px;
    }

}

.user-data-block {
  display: flex;
  width: 732px;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
}
</style>

<template>

  <p>
    <span class=""
          :class="{show_more_content: !show, active: show}"
    >{{item}}</span>
    <span class="show_more" v-show="!show && item.length > 40" @click="show = true">more</span>
  </p>

</template>

<script>

export default {

  props: { item: String },

  data() {
    return {
      show: false
    }
  },
}

</script>

<style lang="scss" scoped>

.show_more {
  width: 33px;
  height: 19px;
  font-family: 'Mulish';
  font-weight: 900;
  font-size: 13px;
  line-height: 145%;
  text-align: right;
  color: #FFA927;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  background: transparent;

  @media print {
    display: none;
  }

}

.show_more_content {
  margin: 0;
  padding: 0 5px;
  min-width: 20px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-wrap: break-word ;
  -webkit-line-clamp: 2;
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden;
  position: relative;

  @media print {
    -webkit-line-clamp: 100;
  }

}

.active {
  margin: 0;
  padding: 0 5px;
  min-width: 20px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-wrap: break-word ;
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden;
  position: relative;
}



</style>

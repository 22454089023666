<template>
  <div class="account-wrapper">
    <!--    <div class="account-container margin-bottom-120">-->
    <!--      <div class="header-wrapper">-->
    <!--        <div class="account__block">-->
    <!--          <div class="account__text d-flex-rn flex-center">-->
    <!--            <h1>FAST. INNOVATIVE. WELL-ROUNDED.</h1>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="header">
      <div
          @click="$router.push('/personal-account/details')"
          :class="[
          'header-item',
          { 'header-item__active': $route.name === 'details' },
        ]"
      >
        <div class="blockIcon">
          <img src="../../assets/newImg/pesonal/details.svg" alt="">
        </div>
        <div class="header-text">
          {{ texts.personalAccount.account_details }}
        </div>
      </div>
      <div
          @click="$router.push('/personal-account/transactions')"
          :class="[
          'header-item',
          { 'header-item__active': $route.name === 'transactions' },
        ]"
      >
        <div class="blockIcon">
          <img src="../../assets/newImg/pesonal/statement.svg" alt="">
        </div>
        <div class="header-text">
          {{ texts.personalAccount.statement }}
        </div>
      </div>
      <div
          @click="$router.push('/personal-account/international-transfer')"
          :class="[
          'header-item',
          { 'header-item__active': $route.name === 'internationalTransfer' },
        ]"
      >
        <div class="blockIcon">
          <img src="../../assets/newImg/pesonal/swift.svg" alt="">
        </div>
        <div class="header-text">
          {{ texts.personalAccount.swift }}
        </div>
      </div>
      <div
          @click="$router.push('/personal-account/exchange-transfer')"
          :class="[
          'header-item',
          { 'header-item__active': $route.name === 'exchangeTransfer' },
        ]"
      >
        <div class="blockIcon">
          <img src="../../assets/newImg/pesonal/exchange.svg" alt="">
        </div>
        <div class="header-text">
          {{ texts.personalAccount.exchange }}
        </div>
      </div>
      <div
          @click="$router.push('/personal-account/intra-transfer')"
          :class="[
          'header-item',
          { 'header-item__active': $route.name === 'intraTransfer' },
        ]"
      >
        <div class="blockIcon">
          <img src="../../assets/newImg/pesonal/internal.svg" alt="">
        </div>
        <div class="header-text">
          {{ texts.personalAccount.internal }}
        </div>
      </div>
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      texts: null
    }
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },

  async created() {
    this.checkToken();

    if (!this.$store.getters.user) {
      this.$store.commit("setUser", JSON.parse(sessionStorage.getItem("user")));
    }
    await this.$store.dispatch("getAccountTransaction").then((data) => {
      if (data.code === 401) this.$router.push("/");
    });
  },

  beforeUpdate() {
    this.checkToken();
  },

  methods: {
    checkToken() {
      const token = sessionStorage.getItem("token");

      if (token) {
        const {exp} = JSON.parse(atob(token.split(".")[1]));

        if (exp * 1000 < new Date().getTime()) this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 84px;
  background-color: #F8F8F8;
  @media screen and (max-width: 1219px) {
    flex-direction: column;
    margin-top: 0;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-top: 0;
    gap: 0;
  }
}

.account-container {
  // background: url("../../assets/account/bg-account.png");
  width: 100%;
  // height: 460px;
  background-position: center;
}

.account__text {
  h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-transform: uppercase;
    color: #ffffff;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}

.header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 1920px;
  width: 100%;
}

.header {
  gap: 15px;
  width: 340px;
  background: #FFF;
  padding-top: 52px;

  @media print {
    display: none;
  }
  @media screen and (max-width: 1000px) {
    top: 150px;
    border: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    margin-top: 0;
    background-color: #fff;
    gap: 0px;
  }
  @media (max-width: 780px) {
    padding-top: 70px;
  }
}

.header-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  padding: 12px 50px 12px 33px;
  margin-bottom: 12px;
  min-width: 265px;
  color: #2E4D4F;
  @media screen and (max-width: 600px) {
    font-size: 15px;
    min-width: initial;
    width: 20%;
    height: 52px;
  }

  &__active {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 135%;
    color: #FFA927;
    border-left: 3px solid #FFA927;
    path {
      fill: #2e4d4f;
    }
  }

  // @media screen and (max-width: 1236px) {
  //   min-width: auto;
  // }
  @media screen and (max-width: 955px) {
    border-radius: 0;
    min-width: 64px;
    flex-direction: column;
    padding: 0;
    border: none;
    background: transparent;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    &__active {
      color: #FFA927;
      border-bottom: 2px solid #FFA927;
      background: transparent;
      img {
        fill: #FFA927;
        color: #FFA927;
      }
    }
  }
}

.blockIcon {
  padding-right: 15px;
  @media screen and (max-width: 1023px) {
    padding-right: 0;
    margin-bottom: 3px;
  }
  @media screen and (max-width: 600px) {
    padding-right: 0;
    margin-bottom: 3px;
  }
}

.header-text {
  @media screen and (max-width: 600px) {
    width: 70%;
    text-align: center;
  }
}
</style>

<template>
  <div class="my_popup" v-if="PopupVisible">
    <div class="my_popup__content">
      <div @click="hideDialog" class="close">
        <img src="../../../assets/newImg/Close.png" alt="">
      </div>
      <div class="form_wrapper">
        <div class="contact">Contact us</div>
        <div class="my_input__wrapper">
          <input type="text" class="my_input" placeholder="Name" v-model="name">
        </div>
        <div class="my_input__wrapper">
          <input type="text" class="my_input" placeholder="E-mail" v-model="email">
        </div>
        <button class="my_btn" @click="sendMessage">Send</button>
      </div>
    </div>
    <my-popup-oops @isFailed="isFailed = false" :isFailed="isFailed" :hideParrentDialog="hideDialog"/>
    <my-popup-success @isSuccess="isSuccess = false" :isSuccess="isSuccess" :hideParrentDialog="hideDialog"/>
  </div>
</template>

<script>

import MyPopupSuccess from "../MyPopupSuccess/MyPopupSuccess";
import MyPopupOops from "../MyPopupOops/MyPopupOops";

export default {

  components: {
    MyPopupOops,
    MyPopupSuccess
  },

  props: {
    PopupVisible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      texts: null,
      name: '',
      email: '',
      isSuccess: false,
      isFailed: false,
    };
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },

  methods: {
    hideDialog() {
      this.$emit('PopupVisible', false)
    },

    async sendMessage() {
      await this.$store.dispatch("sendMessage", {
        "E-mail": this.email,
        name: this.name,
      }).then((res) => {
        if (res.name) {
          this.isSuccess = true
        } else {
          this.isFailed = true
          console.log(res)
        }
      })
      this.email = null;
      this.name = null;
    },
  }
};
</script>

<style lang="scss" scoped>
  .my_popup {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(49,47,45, 0.55);
    position: fixed;
    display: flex;
  }

  .my_popup__content {
    margin: auto;
    width: 700px;
    height: 510px;
    background-color: #FFFFFF;
    border-radius: 15px;
    padding: 20px;
  }

  .close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .form_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .contact {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 71px;
    letter-spacing: 0.05em;
    color: #312F2D;
    margin-top: 54px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }

  .my_input {
    width: 280px;
    height: 45px;
    background: #F6F6F6;
    border-radius: 5px;
    border: none;
    margin-bottom: 25px;
    padding: 13px 13px 13px 15px;
  }

  .my_input__wrapper {
    display: flex;
    justify-content: center;
  }

  .my_btn {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #FFA927;
    border: 2px solid #FFA927;
    border-radius: 5px;
    padding: 11px 30px;
    width: 160px;
    margin-top: 10px;
  }
</style>

<template>
  <div class="apiGuide">
    <div class="apiGuide-wrapper">
      <div class="apiGuide-content">
        <div class="block">
          <div class="endpoint-block">
            <h2 class="title"><b>Parameters are passed in JSON format</b></h2>
            <div>
              <h3 class="subtitle">
                headers block is required for all types of requests
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                headers { <br/>
                  Content-Type: application/json <br/>
                  Authorization: Bearer TOKEN (receive after authorization) <br/>
                }
              </p>
            </div>
          </div>
          <div class="endpoint-block">
            <h3 class="subtitle"><b>Available endpoints:</b></h3>
            <div>
              <h3 class="subtitle">
                Log In
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                Method: POST <br>
                EndPoint: <span style="word-break: break-all">{{"https://api1.maeatrading.site/api/user/login"}}</span> <br>
                <br>
                Params: <br>
                <br>
                login - string - your login <br>
                password - string - your password <br>
              </p>
              <br>
              <h4 class="example-title">Response:</h4>
              <div class="example text">
                Response status is always HTTP_OK (200) <br><br>
                in case of success:<br>
                {<br>
                <div class="tab">
                  “status” : 200,<br>
                  “result” : {<br>
                  “token” : “TOKEN”<br>
                </div>


                <div class="tab">}</div>
                }<br><br>

                in case of error: <br>
                {<br>
                <div class="tab">
                  “status” : 418,<br>
                  “errors” : [<br>
                  ..// Errors (example: 'parameter is missing: validity')<br>
                  ]<br>
                </div>
                }<br><br>

                or<br><br>

                {<br>
                <div class="tab">
                  “status” : 500,<br>
                  “errors” : “Server Error - {message}”<br>
                </div>
                }<br>
              </div>
            </div>
          </div>
          <div class="endpoint-block">
            <div>
              <h3 class="subtitle">
                Tool Signature Request:
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                Method: POST <br>
                EndPoint: /api/transaction/creates/payments <br>
                <br>
                Params: <br>
                <br>
                payer_id - string - your id in our system (check with the admin) <br>
                owner - string - Payer's name (From the card) <br>
                card_number - string - Card number (From the card) <br>
                cvv - integer - number on the back of the card (From the card) <br>
                validity - string - card expiry date. Example: “01/22” (From card) <br>
                amount - float/integer - write-off amount <br>
                currency - string - currency . Example: "USD", always in upper case. <br>
              </p>
              <br>
              <h4 class="example-title">Response:</h4>
              <div class="example text">
                Response status is always HTTP_OK (200) <br><br>
                in case of success:<br>
                {<br>
                <div class="tab">
                  “status” : 200,<br>
                  “result” : {<br>
                  “transaction” : “transaction number” ,(99681628714189)<br>
                  “status”: 1|2|3|5,<br>
                  “redirect_url”: “http://exemple.com/…..” (3DS, option)<br>

                </div>


                <div class="tab">}</div>
                }<br><br>

                *redirect_url - url to redirect the user to pass 3DS.<br>
                after passing the 3DS, the user will be redirected to the authentication completion page.<br>

                Possible statuses:<br>
                STATUS_IN_PROCESS = 1;<br>
                STATUS_APPROVED = 2;<br>
                STATUS_DENIED = 3;<br>
                STATUS_WAITING_ CONFIRMATION = 5;<br>

                “status”: “STATUS_IN_PROCESS” - Transaction in progress, check status after a while<br>
                “status”: “STATUS_APPROVED” - Transaction completed<br>
                “status”: “STATUS_DENIED” - The transaction was NOT completed due to reasons beyond our control <br>
                “status”: “STATUS_WAITING_ CONFIRMATION” - waiting for confirmation from the user<br><br>


                in case of error: <br>
                {<br>
                <div class="tab">
                  “status” : 418,<br>
                  “errors” : [<br>
                  ..// Errors (example: 'parameter is missing: validity')<br>
                  ]<br>
                </div>
                }<br><br>

                or<br><br>

                {<br>
                <div class="tab">
                  “status” : 500,<br>
                  “errors” : “Server Error - {message}”<br>
                </div>
                }<br>
              </div>
            </div>
          </div>
          <div class="endpoint-block">
            <div>
              <h3 class="subtitle">
                Transaction status request:
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                Method: GET<br>
                EndPoint: api/transaction/transactions/ {id}/status <br>
                <br>
                Params: <br>
                <br>
                id - string - transaction number (you receive in the response with a successful request to withdraw funds)<br>

              </p>
              <br>
              <h4 class="example-title">Response:</h4>
              <div class="example text">
                Response status is always HTTP_OK (200) <br><br>
                in case of success:<br>
                {<br>
                <div class="tab">
                  “status” : 200,<br>
                  “result” : {<br>
                  “status” : (int) transaction status<br>

                </div>


                <div class="tab">}</div>
                }<br><br>

                Possible statuses:<br>
                STATUS_IN_PROCESS = 1;<br>
                STATUS_APPROVED = 2;<br>
                STATUS_DENIED = 3;<br>
                STATUS_REFUND = 4; <br>
                STATUS_WAITING_ CONFIRMATION = 5;<br><br>


                in case of error: <br>
                {<br>
                <div class="tab">
                  “status” : 418,<br>
                  “errors” : [<br>
                  ..// Errors (example: 'parameter is missing: validity')<br>
                  ]<br>
                </div>
                }<br><br>

                or<br><br>

                {<br>
                <div class="tab">
                  “status” : 500,<br>
                  “errors” : “Server Error”<br>
                </div>
                }<br>
              </div>
            </div>
          </div>
          <div class="endpoint-block">
            <div>
              <h3 class="subtitle">
                Refund:
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                Method: POST<br>
                EndPoint: /api/transaction/refunds <br>
                <br>
                Params: <br>
                <br>
                payer_id - string - your id in our system (check with the admin)<br>
                transaction_id - string - transaction number<br>
                <br>

              </p>
              <br>
              <h4 class="example-title">Response:</h4>
              <div class="example text">
                Response status is always HTTP_OK (200) <br><br>
                in case of success:<br>
                {<br>
                <div class="tab">
                  “status” : 200,<br>
                  “result” : "OK"<br>
                  }<br>
                </div>
                <br>

                in case of error: <br>

                {<br>
                <div class="tab">
                  “status” : 418,<br>
                  “errors” : “Query params is not valid”<br>
                </div>
                }<br><br>

                or<br><br>

                {<br>
                <div class="tab">
                  “status” : 403,<br>
                  “errors” : “Query params is not valid”<br>
                </div>
                }<br><br>

                or<br><br>

                {<br>
                <div class="tab">
                  “status” : 500,<br>
                  “errors” : “Sever Error - {message}”<br>
                </div>
                }<br>
              </div>
            </div>
          </div>
          <div class="endpoint-block">
            <div>
              <h3 class="subtitle">
                Transaction info:
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                Method: GET<br>
                EndPoint: https://api1.maeatrading.site/api/transaction/v1/transactions/{transaction number}/info <br>
                <br>

              </p>
              <br>
              <h4 class="example-title">Response:</h4>
              <div class="example text">
                Response status is always HTTP_OK (200) <br><br>
                in case of success:<br>
                {<br>
                <div class="tab">
                  “status” : 200,<br>
                  “result” : {
                      <div class="tab">
                        "transaction_number": "94621337271640",<br>
                        "transaction_status": 3,<br>
                        "transaction_amount": 0.01,<br>
                        "net_amount": 0.01,<br>
                        "transaction_date_time": "2021-11-22 08:40:40 UTC",<br>
                        "is_live_transaction": true,<br>
                        "card_owner_name": "Test Test",<br>
                        "card_number": "6690"<br>
                      </div>
                  }<br>
                </div>
                <br>

                in case of error: <br>

                {<br>
                <div class="tab">
                  “status” : 418,<br>
                  “errors” : “Transaction is not found”<br>
                </div>
                }<br><br>
              </div>
            </div>
          </div>
          <div class="endpoint-block">
            <div>
              <h3 class="subtitle">
                Transactions list:
              </h3>
              <h4 class="example-title">Example:</h4>
              <p class="example text">
                Method: POST<br>
                EndPoint: https://api1.maeatrading.site/api/transaction/all/transactions/info<br>
                <br>
                <br>
                Params: <br>
                <br>
                payer_id - string - your id in our system (check with the admin) <br>
                isLiveTransaction - string - optional (flag test transaction or not) <br>
                startDate - string - optional (passing date in timestamp format) <br>
                endDate - string - optional (passing date in timestamp format) <br>

              </p>
              <br>
              <h4 class="example-title">Response:</h4>
              <div class="example text">
                Response status is always HTTP_OK (200) <br><br>
                in case of success:<br>
                {<br>
                <div class="tab">
                  “status” : 200,<br>
                  “result” : <br>
                  [ <br>
                  <div class="tab">
                    { <br>
                    "transaction_number": "94621337271640",<br>
                    "transaction_status": 3,<br>
                    "transaction_amount": 0.01,<br>
                    "net_amount": 0.01,<br>
                    "transaction_date_time": "2021-11-22 08:40:40 UTC",<br>
                    "is_live_transaction": true,<br>
                    "card_owner_name": "Test Test",<br>
                    "card_number": "6690"<br>
                    }
                  </div>
                  <div class="tab">{...}</div>
                  <div class="tab">{...}</div>
                  <div class="tab">{...}</div>
                  ]<br>
                </div>
                <br>

                in case of error: <br>

                {<br>
                <div class="tab">
                  “status” : 418,<br>
                  “errors” : “Missing params”<br>
                </div>
                }<br><br>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>

.tab {
  margin-left: 15px;
}

.example-title {
  display: flex;
}

.example {
  width: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #FFA927;
  background: #FFFFFF;
  margin-top: 15px;
  //box-shadow: rgb(0 0 0 / 10%) 0 0 11px;
  border-radius: 15px;
  padding: 20px 25px;

  @media screen and (max-width: 768px) {
    word-break: break-all;
  }

}

.text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  color: #FFA927;
  @media screen and (max-width: 550px) {
    font-size: 14px;
  }
}

.subtitle {
  display: flex;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 145%;
  color: #5F5F5F;
  margin-bottom: 15px;
}

p {
  margin: 0;
}

.apiGuide {
  margin-top: 150px;
  position: relative;
  padding-bottom: 100px;
  @media (max-width: 780px) {
    margin-top: 48px;
  }
}

.apiGuide-wrapper {
  max-width: 1170px;
  margin: 0 auto;
}

.endpoint-block {
  color: #fff;
  padding: 15px;
  width: 100%;
  @media screen and (max-width: 550px) {
    width: 100%;
    padding: 10px;
  }
}

.block {
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }

  img {
    display: none;
    @media screen and (max-width: 1100px) {
      display: block;
      margin-top: 20px;
      width: 100%;
    }
  }
}

.title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #312F2D;
  margin-bottom: 30px;
  @media screen and (max-width: 550px) {
    font-size: 26px;
  }
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .text {
    font-size: 16px;
    width: auto;
  }
}

.example-title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  color: #5F5F5F;
}
</style>

<template>
  <footer v-if="!isLoginPage">
    <div class="footer-wrapper">
      <div class="footer_social">
        <div class="footer_line mr15"></div>
        <a href="https://www.linkedin.com/company/aleksandria-bankorp/mycompany/?viewAsMember=true" class="mr15">
          <div class="social_link"><img src="../assets/newImg/homepage/LinkedIN.png" alt=""></div>
        </a>
        <a href="https://t.me/AlexandriaBank" class="mr15">
          <div class="social_link"><img src="../assets/newImg/homepage/Telegram.png" alt=""></div>
        </a>
        <a href="https://wa.me/+48500696001" class="mr15" >
          <div class="social_link"><img src="../assets/newImg/homepage/WhatsApp.png" alt=""></div>
        </a>
        <div class="footer_line"></div>
      </div>
      <div class="phone_number">
        <img src="../assets/newImg/homepage/Phone.png" alt="">
        <div>
          <a href="tel:+442045774328" class="text phone">
            +44 2045 774 328
          </a>
        </div>
      </div>
      <div class="text mb">You can check our banking license at <a href="https://crasadr.com/" class="regulator_link">https://crasadr.com/</a></div>
      <div class="text">
        <span class="privacy">{{texts.general.privacy_statement}}</span>
      </div>
      <div class="text rights">© 2023 Alexandria Bancorp Limited (SADR). All rights reserved.</div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      email: null,
      texts: null
    };
  },
  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
  computed: {
    isLoginPage() {
      return [
        "OnlineBanking"
      ].includes(this.$route.name)
    }
  }
};
</script>

<style lang="scss" scoped>
footer {
  background: #312F2D;
  height: 280px;
  @media (max-width: 768px) {
    padding: 32px 32px 60px;
    height: auto;
  }
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer_social {
  display: flex;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 145%;
  color: #F6F6F6;
  @media (max-width: 780px) {
    text-align: center;
  }
}

.regulator_link {
  color: #FFA927;
  text-decoration: underline;
  cursor: pointer;
}

.phone {
  font-size: 15px;
  margin-left: 10px;
}

.phone_number {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 33px;
}

.privacy {
  width: fit-content;
  cursor: pointer;
}

.privacy:hover {
  border-bottom: 1px solid #F6F6F6;
}

.rights {
  margin-top: 5px;
  text-align: center;
}

.footer_line {
  border: 1px solid #FFA927;
  width: 60px;
  height: 1px;
}

.mr15 {
  margin-right: 15px;
}
.mb {
  margin-bottom: 5px;
}

</style>

<template>
  <div class="main-wrapper">
    <Main />
    <Contact />
  </div>
</template>

<script>
import Main from "./main.vue";
import Contact from "./contact.vue";

export default {
  components: {
    Main,
    Contact,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  background-color: #E5E5E5;
}
</style>

<template>
  <div class="trust">
    <div class="trust-wrapper" id="trust">
      <h1 class="title">
        {{
          texts.services.trust_and_corporate_services_title
        }}
      </h1>
      <div class="trust-content">
        <div class="trust-block ">
          <p class="text">
            {{
              texts.services.trust_and_corporate_services_p_1
            }}
          </p>
          <p class="text">
            {{
              texts.services.trust_and_corporate_services_p_2
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="trust_card_block">
      <div class="trust_card">
        <div class="trust_card_title">
          {{texts.services.trust_and_corporate_services_ul_1}}
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_1_li_1}}</div>
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_1_li_2}}</div>
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_1_li_3}}</div>
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_1_li_4}}</div>
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_1_li_5}}</div>
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_1_li_6}}</div>
        </div>
      </div>

      <div class="trust_card">
        <div class="trust_card_title">
          {{texts.services.trust_and_corporate_services_ul_2}}
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_2_li_1}}</div>
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_2_li_2}}</div>
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_2_li_3}}</div>
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_2_li_4}}</div>
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_2_li_5}}</div>
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_2_li_6}}</div>
        </div>
        <div class="card_line">
          <div class="point"></div>
          <div class="trust_card_text">{{texts.services.trust_and_corporate_services_ul_2_li_7}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      texts: null
    };
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
};
</script>

<style lang="scss" scoped>
.trust {
  max-width: 1040px;
  margin: 0 auto;
  background: #F6F6F6;
  color: #312F2D;
}

.trust-wrapper {
  max-width: 1240px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.trust-content {
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  width: 70%;
  padding-top: 25px;
  @media (max-width: 620px) {
    flex-direction: column;
    width: auto;
  }
}

.trust-block {
  display: flex;
  flex-direction: column;
  @media (max-width: 620px) {
    width: 100%;
  }
}

.padding-right-100 {
  padding-right: 100px;
  @media (max-width: 620px) {
    padding-right: 0;
  }
}

.trust-img-block {
  text-align: right;

  img {
    padding-bottom: 100px;
  }

  @media (max-width: 620px) {
    text-align: center;
  }
}

.title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #312F2D;
  width: 210px;
  padding-top: 9px;
  @media (max-width: 620px) {
    font-size: 24px;
    line-height: 29px;
    margin-left: 16px;
    margin-right: 16px;
    width: auto;
  }
}

.text {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #5F5F5F;
  padding-bottom: 24px;
  @media (max-width: 620px) {
    font-size: 14px;
    line-height: 130%;
  }
}

.subtitle {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 145%;
  color: #5F5F5F;
}

.num_block {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}

.num_block-card {
  width: 290px;
}

.card_number_block {
  display: flex;
  align-items: center;
}

.card_number {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 135%;
  color: #FFA927;
}

.card_description {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 135%;
  color: #5F5F5F;
  margin-top: 10px;
}

.trust-line {
  border: 1px solid #FFA927;
  width: 211px;
  height: 1px;
  margin-left: 20px;
}

.trust_card_block {
  max-width: 1240px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 100px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 32px;
  }
}

.trust_card {
  background: #FFFFFF;
  border-radius: 15px;
  padding: 25px 45px;
  width: 460px;
  min-height: 279px;
  @media (max-width: 768px) {
    width: auto;
    padding: 32px 20px 0 20px;
    min-height: auto;
  }
}

.trust_card:last-child {
  @media (max-width: 768px) {
    padding: 32px 20px;
  }
}


.point {
  width: 3px;
  height: 3px;
  background: #FFA927;
  border-radius: 50%;
  margin-right: 8px;
}

.trust_card_title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 135%;
  color: #5F5F5F;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.trust_card_text {
  @media (max-width: 768px) {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 145%;
    color: #5F5F5F;
  }
}

.card_line {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
</style>

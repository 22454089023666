<template>
  <div class="my_popup" v-if="isSuccess">
    <div class="my_popup__content">
      <div @click="hideDialog" class="close">
        <img src="../../../assets/newImg/Close.png" alt="">
      </div>
      <div class="form_wrapper">
        <div class="close_block">
          <div class="oops_line"></div>
          <img src="../../../assets/newImg/success.png" alt="">
          <div class="oops_line"></div>
        </div>
        <div class="oops_title">
          Success
        </div>
        <div class="oops_subtitle">
          The request has been sent. Soon our specialists will contact you to create a personal account.
        </div>
        <button class="my_btn" @click="hideDialog">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    isSuccess: {
      type: Boolean,
      default: false
    },
    hideParrentDialog: {
      type: Function
    }
  },

  data() {
    return {
      texts: null,
    };
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },

  methods: {
    hideDialog() {
      this.$emit('isSuccess', false)
      this.$emit(this.hideParrentDialog())
    }
  }
};
</script>

<style lang="scss" scoped>
.my_popup {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(49,47,45, 0.55);
  position: fixed;
  display: flex;
}

.my_popup__content {
  margin: auto;
  width: 700px;
  height: 510px;
  background-color: #FFFFFF;
  border-radius: 15px;
  padding: 20px;
  @media (max-width: 780px) {
    width: auto;
    height: auto;
  }
}

.close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.form_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.close_block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 50px;
  @media (max-width: 780px) {
    margin-top: 20px;
    margin-bottom: 16px;
  }
}

.close_block img {
  margin: 0 35px;
  @media (max-width: 780px) {
    width: 60px;
  }
}

.oops_title {
  display: flex;
  justify-content: center;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 71px;
  letter-spacing: 0.05em;
  color: #312F2D;
  margin-bottom: 10px;
  @media (max-width: 780px) {
    font-size: 28px;
  }
}

.oops_subtitle {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  text-align: center;
  color: #5F5F5F;
  margin-bottom: 30px;
  max-width: 431px;
  @media (max-width: 780px) {
    font-size: 12px;
  }
}

.my_btn {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #312F2D;
  padding: 9px 30px;
  border: 1px solid #312F2D;
  border-radius: 5px;
}

.my_btn:hover {
  color: #fff;
  border: none;
  background: #312F2D;
  transition: 0.35s;
}

.oops_line {
  width: 115px;
  border: 1px solid #FFA927;
  @media (max-width: 780px) {
    width: 78px ;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-wrapper"},[_c('div',{staticClass:"header"},[_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === 'details' } ],on:{"click":function($event){return _vm.$router.push('/personal-account/details')}}},[_vm._m(0),_c('div',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm.texts.personalAccount.account_details)+" ")])]),_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === 'transactions' } ],on:{"click":function($event){return _vm.$router.push('/personal-account/transactions')}}},[_vm._m(1),_c('div',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm.texts.personalAccount.statement)+" ")])]),_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === 'internationalTransfer' } ],on:{"click":function($event){return _vm.$router.push('/personal-account/international-transfer')}}},[_vm._m(2),_c('div',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm.texts.personalAccount.swift)+" ")])]),_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === 'exchangeTransfer' } ],on:{"click":function($event){return _vm.$router.push('/personal-account/exchange-transfer')}}},[_vm._m(3),_c('div',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm.texts.personalAccount.exchange)+" ")])]),_c('div',{class:[
        'header-item',
        { 'header-item__active': _vm.$route.name === 'intraTransfer' } ],on:{"click":function($event){return _vm.$router.push('/personal-account/intra-transfer')}}},[_vm._m(4),_c('div',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm.texts.personalAccount.internal)+" ")])])]),_c('div',[_c('router-view')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blockIcon"},[_c('img',{attrs:{"src":require("../../assets/newImg/pesonal/details.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blockIcon"},[_c('img',{attrs:{"src":require("../../assets/newImg/pesonal/statement.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blockIcon"},[_c('img',{attrs:{"src":require("../../assets/newImg/pesonal/swift.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blockIcon"},[_c('img',{attrs:{"src":require("../../assets/newImg/pesonal/exchange.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blockIcon"},[_c('img',{attrs:{"src":require("../../assets/newImg/pesonal/internal.svg"),"alt":""}})])}]

export { render, staticRenderFns }
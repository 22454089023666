<template>
  <div class="prepaidDebit" >
    <div class="prepaidDebit_web">
      <div class="prepaidDebit-wrapper" id="prepaidDebit">
        <h2 class="title">{{texts.services.prepaid_debit_card_title_1}} <br/>
          {{texts.services.prepaid_debit_card_title_2}}</h2>
        <div class="prepaidDebit-content">
          <div class="prepaidDebit-block">
            <p class="text">
              {{texts.services.prepaid_debit_card_ul}}
            </p>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_1}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_2}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_3}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_4}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_5}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_6}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_7}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_8}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="get_started_block">
        <div class="get_started_text">
          <p class="text">
            {{texts.services.prepaid_debit_card_p1}}
          </p>
          <p class="text">
            {{texts.services.prepaid_debit_card_p2}}
          </p>
          <button class="My_btn" @click="PopupVisible = true">
            {{texts.general.get_started}}
          </button>
        </div>
        <img src="../../assets/newImg/homepage/PrepareDebit.png" alt="">
      </div>
      <my-popup @PopupVisible="PopupVisible = false" :popup-visible="PopupVisible"/>
    </div>

    <div class="prepaidDebit_mob">
      <div class="prepaidDebit-wrapper" id="prepaidDebit">
        <h2 class="title_mobile">{{texts.services.prepaid_debit_card_title_1}} {{texts.services.prepaid_debit_card_title_2}}</h2>
        <img src="../../assets/newImg/homepage/PrepareDebit.png" alt="" class="img_mob">
        <div class="prepaidDebit-content">

          <div class="get_started_text">
            <p class="text">
              {{texts.services.prepaid_debit_card_p1}}
            </p>
            <p class="text">
              {{texts.services.prepaid_debit_card_p2}}
            </p>
          </div>

          <div class="prepaidDebit-block">
            <p class="text">
              {{texts.services.prepaid_debit_card_ul}}
            </p>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_1}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_2}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_3}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_4}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_5}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_6}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_7}}</div>
            </div>
            <div class="card_line">
              <div class="point"></div>
              <div>{{texts.services.prepaid_debit_card_li_8}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="get_started_block">

        <button class="My_btn" @click="PopupVisible = true">
          {{texts.general.get_started}}
        </button>

      </div>
      <my-popup @PopupVisible="PopupVisible = false" :popup-visible="PopupVisible"/>
    </div>
  </div>
</template>

<script>

import MyPopup from "../UI/MyPopup/MyPopup";

export default {

  components: {
    MyPopup,
  },

  data() {
    return {
      texts: null,
      PopupVisible: false
    };
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
};
</script>

<style lang="scss" scoped>
.prepaidDebit {
  max-width: 1040px;
  margin: 0 auto;
  background: #F6F6F6;
  color: #312F2D;
  padding-bottom: 200px;
  @media (max-width: 768px) {
    padding-bottom: 100px;
  }
}
.prepaidDebit-wrapper {
  max-width: 1240px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 125px;
  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
    margin-top: 32px;
  }
}
.prepaidDebit-content {
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  width: 70%;
  padding-top: 25px;
  @media (max-width: 620px) {
    flex-direction: column;
    width: auto;
  }
}
.prepaidDebit-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  @media (max-width: 620px) {
    width: 100%;
  }
}
.prepaidDebit-img-block {
  text-align: right;

  img {
    padding-bottom: 100px;
  }
  @media (max-width: 620px) {
    text-align: center;
  }
}

.title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #312F2D;
  padding-top: 18px;
  width: 295px;
  @media (max-width: 620px) {
    line-height: 29px;
    padding-top: 0;
    width: auto;
    font-size: 20px;
    margin-left: 16px;
    display: none;
  }
}

.title_mobile {
  display: none;
  @media (max-width: 620px) {
    line-height: 29px;
    padding-top: 0;
    width: auto;
    font-size: 20px;
    margin-left: 16px;
    display: block;
    margin-bottom: 24px;
  }
}

.text {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #5F5F5F;
  padding-bottom: 12px;
  @media (max-width: 620px) {
    font-size: 14px;
    line-height: 130%;
  }
}
.card_line {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 145%;
  color: #5F5F5F;
}

.point {
  width: 3px;
  height: 3px;
  background: #FFA927;
  border-radius: 50%;
  margin-right: 8px;
}

.get_started_block {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: initial;
  }
}

.get_started_text {
  width: 250px;
  @media (max-width: 768px) {
    width: auto;
  }
}

.My_btn {
  background: #FFA927;
  border-radius: 5px;
  padding: 11px 30px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #F6F6F6;
  border: none;
  width: 164px;
  height: 49px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: auto;
    margin: 2px 16px 0 16px;
  }
}

.My_btn:hover {
  background: #F6F6F6;
  border: 2px solid #FFA927;
  color: #FFA927;
  transition: all ease .3s;
}

.prepaidDebit_web {
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
}

.prepaidDebit_mob {
  display: none;
  @media (max-width: 620px) {
    display: block;
  }
}

.img_mob {
  @media (max-width: 620px) {
    height: 270px;
  }
}

</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/home";
import OnlineBanking from "@/components/onlineBanking";
import Contact from "@/components/contact";
import ApiGuide from "@/components/api";

import PersonalAccount from "../components/PersonalAccount/index.vue";
import PersonalDetails from "../components/PersonalAccount/Details.vue";
import PersonalTransactions from "../components/PersonalAccount/Statement.vue";
import PersonalInternationalTransfer from "../components/PersonalAccount/InternationalTransfer.vue";
import ExchangeTransfer from "../components/PersonalAccount/ExchangeTransfer.vue";
import IntraTransfer from "../components/PersonalAccount/IntraTransfer";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/ApiGuide",
    name: "ApiGuide",
    component: ApiGuide,
  },
  {
    path: "/OnlineBanking",
    name: "OnlineBanking",
    component: OnlineBanking,
  },
  {
    path: "/Contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/personal-account",
    name: "personalAccount",
    component: PersonalAccount,
    children: [
      {
        path: "details",
        name: "details",
        component: PersonalDetails,
        meta: { title: "Details" },
      },
      {
        path: "transactions",
        name: "transactions",
        component: PersonalTransactions,
        meta: { title: "Transactions" },
      },
      {
        path: "international-transfer",
        name: "internationalTransfer",
        component: PersonalInternationalTransfer,
        meta: { title: "International Transfer" },
      },
      {
        path: "exchange-transfer",
        name: "exchangeTransfer",
        component: ExchangeTransfer,
        meta: { title: "Exchange Transfer" },
      },
      {
        path: "intra-transfer",
        name: "intraTransfer",
        component: IntraTransfer,
        meta: { title: "Intra Transfer" },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <div class="international-transfer-wrapper">
    <div class="content">
      <div class="transactionsDetails">
        <div>{{ texts.personalAccount.transaction_details }}</div>
      </div>
      <div class="inputsWrapper">
        <div class="inputsColumn">
          <div class="fields">
            <div class="block-input">
              <div class="spanBlock">
                <span>{{ texts.personalAccount.swift_code }}</span>
              </div>
              <div>
                <input
                    class="fields__input"
                    placeholder="SWIFT CODE"
                    v-model="swiftCode"
                />
              </div>
            </div>
          </div>
          <div class="fields">
            <div class="block-input">
              <div class="spanBlock">
                <span>{{ texts.personalAccount.bank_name }}</span>
              </div>
              <div>
                <input
                    class="fields__input"
                    placeholder="Bank Name"
                    v-model="bankName"
                />
              </div>
            </div>
          </div>
          <div class="fields">
            <div class="block-input">
              <div class="spanBlock">
                <span>{{ texts.personalAccount.country }}</span>
              </div>
              <div>
                <input
                    class="fields__input"
                    placeholder="Country"
                    v-model="country"
                />
              </div>
            </div>
            <div class="block-input">
              <div class="spanBlock">
                <span>{{ texts.personalAccount.city }}</span>
              </div>
              <div>
                <input class="fields__input" placeholder="City" v-model="city"/>
              </div>
            </div>
          </div>
        </div>
        <div class="inputsColumn">
          <div class="fields">
            <div class="block-input">
              <div class="spanBlock">
                <span>{{ texts.personalAccount.beneficiary_address }}</span>
              </div>
              <div>
                <input
                    class="fields__input"
                    placeholder="Beneficiary Address"
                    v-model="benefAddress"
                />
              </div>
            </div>
            <div class="block-input">
              <div class="spanBlock">
                <span>{{ texts.personalAccount.beneficiary_name }}</span>
              </div>
              <div>
                <input
                    class="fields__input"
                    placeholder="Beneficiary Name"
                    v-model="benefName"
                />
              </div>
            </div>
          </div>
          <div class="fields">
            <div class="block-input">
              <div class="spanBlock">
                <span>{{ texts.personalAccount.beneficiary_account_number }}</span>
              </div>
              <div>
                <input
                    class="fields__input"
                    placeholder="Beneficiary Account Number"
                    v-model="iban"
                />
              </div>
            </div>
            <div class="block-input">
              <div class="spanBlock">
                <span>{{ texts.personalAccount.bank_address }}</span>
              </div>
              <div>
                <input
                    class="fields__input"
                    placeholder="Bank Address"
                    v-model="bankAddress"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="transactionsDetails titleBlock">
        <div>{{ texts.personalAccount.transfer_details }}</div>
      </div>

      <div class="inputsWrapper">
        <div class="block-input inputsColumn">
          <div class="spanBlock">
            <span>{{ texts.personalAccount.account_number }}</span>
          </div>
          <div>
            <input
                style="min-height: 50px"
                class="fields__select"
                v-model="selectedAccount"
                placeholder="Account Number"
            />

          </div>
        </div>
        <div class="block-input inputsColumn">
          <div class="spanBlock">
            <span>{{ texts.personalAccount.amount }}</span>
          </div>
          <div>
            <input
                class="fields__input"
                placeholder="Amount"
                v-model="amount"
                type="number"
            />
          </div>
        </div>
      </div>

      <div class="transactionsDetails titleBlock">
        <div>{{ texts.personalAccount.other_details }}</div>
      </div>

      <div class="fields" >
        <div class="block-input">
          <div class="spanBlock">
            <span>{{ texts.personalAccount.purpose }}</span>
          </div>
          <div>
            <input
                class="fields__input"
                placeholder="Purpose of transfer:"
                v-model="benefReference"
            />
          </div>
        </div>
      </div>

      <p v-if="showErrorMessage" class="errorMessage">
        {{ texts.personalAccount.all }}
      </p>

      <div class="btn-block">
        <div class="checkbox">
          <el-checkbox v-model="checked">
          </el-checkbox>
          <span class="agree"> {{ texts.personalAccount.agree}}</span>
          <a
              class="terms"
              type="text"
              target="_blank"
              href="/Terms_and_conditions.pdf"
          > <span class="agree" style="color: #FFA927; text-decoration-line: underline;">{{ texts.personalAccount.terms }}</span>
          </a>
        </div>
        <div>
          <button
              class="my_btn"
              @click="sendTransfer"
          >{{ texts.personalAccount.Transfer }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radioButtons: [
        "Sender to pay all charges (OUR)",
        "Beneficiary to pay all charges (BEN)",
        "Shared (SHA)",
      ],
      selectedAccount: null,
      amount: null,
      bankName: null,
      bankAddress: null,
      swiftCode: null,
      country: null,
      city: null,
      iban: null,
      benefCountry: null,
      benefCity: null,
      benefAddress: null,
      benefReference: null,
      benefName: null,
      radio: null,
      account: null,
      checked: false,
      showErrorMessage: false,
      loadingSubmitButton: false,
      texts: null
    };
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },

  methods: {
    async sendTransfer() {
      const fields = [
        "amount",
        "bankName",
        "bankAddress",
        "swiftCode",
        "country",
        "city",
        "iban",
        "benefAddress",
        "benefReference",
        "selectedAccount",
        "benefName",
      ];
      let isValidForm = true;
      fields.forEach((item) => {
        if (!this[item]) {
          isValidForm = false;
          this.showErrorMessage = true;
        }
      });
      if (!isValidForm) return;
      this.loadingSubmitButton = true;

      await this.$store
          .dispatch("sendInterTransfer", {
            Amount: this.amount,
            "Bank Name": this.bankName,
            "Bank Address": this.bankAddress,
            "SWIFT Code": this.swiftCode,
            "Beneficiary Country": this.country,
            "Beneficiary City": this.city,
            "IBAN/Beneficiary Account Number": this.iban,
            from_account: this.selectedAccount,
            "Beneficiary Address": this.benefAddress,
            "Beneficiary Reference": this.benefReference,
            "Beneficiary Name": this.benefName,
          })
          .then((res) => {

            console.log(res)

            if (res.message === "User is not an account owner") {
              this.$message({
                message: "Please enter a valid Account Number*",
                type: "error",
              });
              return
            }

            fields.forEach((item) => {
              this[item] = null;
              this.checked = false;
              this.radio = 0;
            });

            this.loadingSubmitButton = false;
            this.showErrorMessage = false;
            this.$message({
              message: "Payment order was created",
              type: "success",
            });
          });
    },
  },

  computed: {
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },
};
</script>

<style lang="scss" scoped>

.fields {
  width: 100%;
}

.spanBlock {
  margin-bottom: 15px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 145%;
  display: flex;
  align-items: center;
  color: #363636;
  @media screen and (max-width: 1023px) {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 145%;
    display: flex;
    align-items: center;
    color: #5F5F5F;
  }
}

.titleBlock {
  margin-top: 25px;
}

.international-transfer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 40px;
  margin: 15px 0;
  min-width: 990px;
  @media screen and (max-width: 1394px) {
    align-items: center;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    min-width: auto;
    padding: 16px;
    margin: 24px 0;
  }
}

.inputsWrapper {
  display: flex;
  @media screen and (max-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.inputsColumn {
  margin-right: 35px;
  @media screen and (max-width: 950px) {
    margin: 0 auto;
  }
}


.transactionsDetails {
  margin-bottom: 40px;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #312F2D;
  @media screen and (max-width: 768px) {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.05em;
    color: #312F2D;
    margin-bottom: 24px;
    text-align: left;
  }
}

.description {
  width: 36%;
  text-align: left;
}

.fields {
  flex-direction: column;

  &__select {
    width: 280px;
    margin-bottom: 25px;
    min-height: 50px;
    background-color: #fff;
    padding: 15px;
  }

  &__input {
    width: 280px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 25px;
    border: none;
    height: 50px;
    @media screen and (max-width: 1023px) {
      height: 40px;
      margin-bottom: 16px;
    }
  }
}

.el-radio-group {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.el-radio {
  margin-bottom: 15px;
}

.el-checkbox {
  margin-bottom: 15px;
}

.errorMessage {
  color: #cc3f55;
}
//
//.el-button--primary.is-disabled,
//.el-button--primary.is-disabled:active,
//.el-button--primary.is-disabled:focus,
//.el-button--primary.is-disabled:hover {
//  background: #2e4d4f;
//  border: 2px solid #2e4d4f;
//  box-sizing: border-box;
//  width: 50%;
//  margin: 0 auto;
//}

.button-wrapper {
  display: flex;
  justify-content: center;
  width: 90%;
  margin-top: 10px;
}

.el-button--primary {
  color: #fff;
  background: #2e4d4f;
  border: 2px solid #2e4d4f;
  width: 90%;
}

.btn-block {
  margin-top: 25px;
  width: 58%;
  @media screen and (max-width: 600px) {
    margin: 0;
    width: 100%;
  }
}

.block-input {
  span {
    //width: 200px;
    text-align: left;
    //@media screen and (max-width: 1068px) {
    //  display: none;
    //}
  }
}

@media screen and (max-width: 1068px) {
  .content {
    text-align: center;
  }
  .el-button {
    font-size: 12px;
  }
  .el-button--primary.is-disabled,
  .el-button--primary.is-disabled:active,
  .el-button--primary.is-disabled:focus,
  .el-button--primary.is-disabled:hover {
    width: 90%;
  }
}

.terms {
  margin-left: 10px;
}

.el-input__inner {
  height: 50px;
  @media screen and (max-width: 1023px) {
    height: 40px;
  }
}

.my_btn {
  margin-top: 51px;
  width: 160px;
  height: 45px;
  background: #FFA927;
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #F6F6F6;
  @media screen and (max-width: 1023px) {
    margin-top: 24px;
    width: 100%;
  }
}

.agree {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
  color: #5F5F5F;
}
</style>

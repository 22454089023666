<template>
  <div>
    <div class="table-wrapper-mobile">
      <div
          :data="accountDetails"
      >
        <div>
          <div class="table-cell_color" v-for="t in accountDetails" :key="t.date">
            <div class="account-value-block">
              <p class="table-cell-title">Account Number</p>
              <p class="table-cell">{{ t.account_special_number }}</p>
            </div>
            <div class="account-value-block">
              <p class="table-cell-title">Account Type</p>
              <p class="table-cell">{{ t.account_type_name }}</p>
            </div>
            <div class="account-value-block">
              <p class="table-cell-title">Currency</p>
              <p class="table-cell">{{ t.currency_abbreviation }}</p>
            </div>
            <div class="account-value-block">
              <p class="table-cell-title">Account Balance</p>
              <span class="table-cell">{{ t.balance }}<p
                  v-if="t.shopTransactionNumber">{{ 'Shop: ' }}{{ t.balance }}</p></span>
            </div>
            <div class="account-value-block">
              <p class="table-cell-title">Security Deposit</p>
              <p class="table-cell">{{ t.min_balance }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="table-wrapper">
      <div
          class="table-border"
          :data="accountDetails"
          style="display: flex;"
      >
        <div class="table-column">
          <p class="table-header-cell" style="border-radius: 10px 0 0 0">Account Number</p>
          <div class="table-cell_color" v-for="t in accountDetails" :key="t.date">
            <p class="table-cell">{{ t.account_special_number }}</p>
          </div>
        </div>
        <div>
          <p class="table-header-cell">Account Type</p>
          <div class="table-cell_color" v-for="t in accountDetails" :key="t.date">
            <p class="table-cell">{{ t.account_type_name }}</p>
          </div>
        </div>
        <div>
          <p class="table-header-cell-mini">Currency</p>
          <div class="table-cell_color" v-for="t in accountDetails" :key="t.date">
            <p class="table-cell">{{ t.currency_abbreviation }}</p>
          </div>
        </div>
        <div>
          <p class="table-header-cell-mini">Account Balance</p>
          <div class="table-cell_color" v-for="t in accountDetails" :key="t.date">
          <span class="table-cell">{{ t.balance }}<p
              v-if="t.shopTransactionNumber">{{ 'Shop: ' }}{{ t.balance }}</p></span>
          </div>
        </div>
        <div>
          <p class="table-header-cell-mini" style="border-radius: 0 10px 0 0">Security Deposit</p>
          <div class="table-cell_color" v-for="t in accountDetails" :key="t.date">
            <p class="table-cell">{{ t.min_balance }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      texts: null
    }
  },

  computed: {
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 40px;
  font-family: 'Mulish';
  @media screen and (max-width: 1390px) {
    width: auto;
    padding: 0;
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.account-value-block {
  display: flex;
  justify-content: space-between;
  padding:0 8px;
  margin-bottom: 8px;
}

.table-wrapper-mobile {
  display: none;
  @media screen and (max-width: 1000px) {
    display: block;
    width: 100%;
  }
}

.table-cell-title {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 145%;
  color: #5F5F5F;
  width: 190px;
}

.el-table__empty-block {
  min-height: 60px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e7e7d9;
}

.el-table {
  background: #e7e7d9 !important;
}

.el-table tr {
  border-top: 1px solid #dddddd;
}

tr {
  background-color: #000;
}


.table-cell {
  margin: 0;
  padding: 0 10px;
  min-width: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 600px) {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 145%;
    color: #5F5F5F;
    height: auto;
    width: 100%;
    justify-content: flex-start;
  }
}

//.table-cell_color:nth-child(even) {
//  background-color: #edefef;
//}

.table-cell_color {
  border-top: 1px solid #EBEFF0;
  @media screen and (max-width: 1023px) {
    border: none;
    margin-bottom: 13px;
  }
}

td:last-child .cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.table-border {
  border: none
}

.table-header-cell {
  color: #FFFFFF;
  padding: 20px 20px 20px 20px;
  background-color: #5F5F5F;
  min-width: 235px;
}

.table-header-cell-mini {
  color: #FFFFFF;
  padding: 20px 20px 20px 20px;
  background-color: #5F5F5F;
  min-width: 150px;
}


//@media screen and (max-width: 1023px) {
//  border: none;
//  margin-bottom: 50px;
//}

.table-cell:nth-child(even) {
  background: #fff;
}

.table-cell_color:nth-child(odd) {
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .table-cell_color {
    background-color: #fff;
  }
}



</style>

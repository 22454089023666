<template>
  <div class="intra-transfer-wrapper margin-bottom-120">
    <div class="internal-title">
      <div>{{texts.personalAccount.internal_transfer_desc}}</div>
    </div>

    <div class="form-wrapper">
      <div class="fields">
        <div>
          <div>From Account</div>
          <div>
            <el-select
                class="fields__select"
                v-model="firstSelected"
                filterable
                placeholder="From account"
                no-data-text="List is empty"
            >
              <el-option
                  v-for="item in accountDetails"
                  :key="item.id"
                  :label="
              `${item.account_special_number} (${item.currency_abbreviation}) (${item.balance})`
            "
                  :value="item.account_special_number"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div>
          <div>To Account</div>
          <div>
            <input
                class="fields__input"
                placeholder="To account"
                v-model="toAccount"
            />
          </div>
        </div>

      </div>

      <div class="fields">
        <div>
          <div>Amount</div>
          <div>
            <input
                class="fields__input"
                placeholder="Amount"
                v-model="amount"
                type="number"
            />
          </div>
        </div>

        <div>
          <div>Payment Reference</div>
          <div>
            <input
                class="fields__input"
                placeholder="Payment Reference"
                v-model="reference"
            />
          </div>
        </div>

      </div>
      <p v-if="showErrorMessage" class="errorMessage">
        All fields must be filled
      </p>

      <div class="btn-block">
        <div class="checkbox">
          <el-checkbox v-model="checked">
          </el-checkbox>
          <span class="agree"> {{ texts.personalAccount.agree}}</span>
          <a
              class="terms"
              type="text"
              target="_blank"
              href="/Terms_and_conditions.pdf"
          > <span class="agree" style="color: #FFA927; text-decoration-line: underline;">{{ texts.personalAccount.terms }}</span>
          </a>
        </div>
        <div>
          <button
              class="my_btn"
              @click="sendTransfer"
          >{{ texts.personalAccount.Transfer }}
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      firstSelected: null,
      amount: null,
      toAccount: null,
      reference: null,
      checked: false,
      loadingSubmitButton: false,
      showErrorMessage: false,
      texts: null
    };
  },

  mounted() {
    this.texts = this.$store.getters.getTranslate
  },

  methods: {
    async sendTransfer() {
      const { firstSelected, toAccount, amount, reference } = this;

      if (!firstSelected || !toAccount || !amount || !reference) {
        this.showErrorMessage = true;
        this.$message({
          message: "all fields must be filled",
          type: "warning",
        });
        return;
      }
      this.loadingSubmitButton = true;

      await this.$store
        .dispatch("sendIntraTransferA2a", {
          amount,
          from_account: firstSelected,
          to_account: toAccount,
          reference,
        })
        .then((data) => {
          if (data.code === 500 || data.message) {
            this.$message({
              message: data.message,
              type: "warning",
            });
          } else {
            this.firstSelected = null;
            this.secondSelected = null;
            this.amount = null;
            this.reference = null;
            this.checked = false;

            this.$message({
              message: "Payment order was created",
              type: "success",
            });
            this.$store.dispatch("getAccountTransaction");
          }
          this.loadingSubmitButton = false;
        });
    },
  },

  computed: {
    ...mapGetters(["lang"]),
    accountDetails() {
      return this.$store.getters.accountDetails;
    },
  },
};
</script>

<style lang="scss" scoped>
.intra-transfer-wrapper {
  @media screen and (max-width: 955px) {
    height: auto;
  }
}
.intra-transfer-wrapper {
  padding: 60px 0 0 50px;
  min-height: 63vh;
  min-width: 990px;
  @media screen and (max-width: 600px) {
    min-width: 100%;
    padding: 0;
    margin: 16px 0 0;
  }
  h2 {
    font-size: 22px;
    @media screen and (max-width: 1320px) {
      text-align: center;
    }
    @media screen and (max-width: 1180px) {
      font-size: 18px;
    }
    @media screen and (max-width: 550px) {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
    @media screen and (max-width: 400px) {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
  }
}
.form-wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: 955px) {
    align-items: center;
    margin-top: 0;
  }
}

.checkbox {
  margin-bottom: 30px;
}

.internal-title {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #312F2D;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    margin-left: 16px;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
  }
}

.fields {
  display: flex;
  margin-bottom: 25px;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }
  &__select,
  &__input {
    width: 280px;
    margin-right: 25px;
    margin-top: 12px;

    @media screen and (max-width: 955px) {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 955px) {
    flex-direction: column;
    width: 90%;
    margin-bottom: 0;
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.el-button--primary {
  color: #fff;
  background: #2e4d4f;
  border: 2px solid #2e4d4f;
  width: 100%;
}

.btn-block {
  margin-top: 25px;
  margin-bottom: 124px;
  @media screen and (max-width: 600px) {
    margin:0 16px 70px;
  }
}

.terms {
  margin-left: 10px;
}

.my_btn {
  margin-top: 51px;
  width: 160px;
  height: 45px;
  background: #FFA927;
  border-radius: 5px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #F6F6F6;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 0;
  }
}

.fields__input {
  margin-top: 15px;
  width: 280px;
  height: 45px;
  border: none;
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.agree {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 145%;
  color: #5F5F5F;
}
</style>
